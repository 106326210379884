// Navbar.tsx
import React, { useState } from 'react';
import { FunctionComponent } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthStatus from './AuthStatus';

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from './Button';

const Navbar: FunctionComponent = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const handleAccount = () => {
        navigate('/account');
        setMobileMenuOpen(false);
    };

    const handleBookings = () => {
        navigate('/bookings');
        setMobileMenuOpen(false);
    };

    const handleSignOut = () => {
        sessionStorage.clear();
        localStorage.clear();
        navigate('/login');
        setMobileMenuOpen(false);
    }

    return (
        <div className="w-full flex flex-col items-center justify-center lg:px-5">
            <div className="w-full max-w-[71rem] h-20 flex flex-col items-center">
                <header className="w-full flex flex-row items-center justify-between text-center">
                    <Link to={'/'} className="z-10">
                        <img
                            className="h-8 w-auto"
                            loading="lazy"
                            alt="Venu Logo"
                            src="/venu-logo.svg"
                        />
                    </Link>
                    {/* Hamburger menu button - only visible on mobile */}
                    <button
                        className="hidden lg:block z-30 p-2 bg-transparent"
                        onClick={toggleMobileMenu}
                        aria-label="Toggle mobile menu"
                    >
                        <div className="w-6 flex flex-col gap-1.5">
                            <span className={`block text-white h-0.5 w-full bg-current transform transition duration-300 ease-in-out ${mobileMenuOpen ? 'rotate-45 translate-y-2' : ''}`}></span>
                            <span className={`block text-white h-0.5 w-full bg-current transition duration-300 ease-in-out ${mobileMenuOpen ? 'opacity-0' : 'opacity-100'}`}></span>
                            <span className={`block text-white h-0.5 w-full bg-current transform transition duration-300 ease-in-out ${mobileMenuOpen ? '-rotate-45 -translate-y-2' : ''}`}></span>
                        </div>
                    </button>

                    {/* Desktop Menu - hidden on mobile */}
                    <div className="lg:hidden block">
                        <AuthStatus>
                            {(isLoggedIn, firstName) => isLoggedIn ? (
                                <div id="loggedIn" className="w-full flex flex-row items-center justify-end gap-4">
                                    <Link to="https://about.venu.lk" target='_blank' className="cursor-pointer px-4">
                                        <div className="body interactive-gradient">
                                            About Us
                                        </div>
                                    </Link>
                                    <div className="flex justify-end items-center relative text-center text-white label1">
                                        Game On, {firstName}
                                        <Tooltip title="Account settings">
                                            <IconButton
                                                onClick={handleClick}
                                                size="small"
                                                sx={{ ml: 2 }}
                                                aria-controls={open ? 'account-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                            >
                                                <Avatar sx={{ width: 32, height: 32 }}></Avatar>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Menu
                                        anchorEl={anchorEl}
                                        id="account-menu"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={handleClose}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                background: '#fff',
                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                padding: '8px 12px',
                                                borderRadius: '16px',
                                                mt: 1.5,
                                                '& .MuiAvatar-root': {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                '&::before': {
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 18,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: '#fff',
                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                    zIndex: 0,
                                                },
                                            },
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        <MenuItem onClick={handleAccount} className='flex mb-2 text-navy-blue gap-3 body p-0'>
                                            <img src="/account_icon.svg" alt="" /> Manage Account
                                        </MenuItem>
                                        <MenuItem onClick={handleBookings} className='flex mb-2 text-navy-blue gap-3 body p-0'>
                                            <img src="/booking_icon.svg" alt="" /> Your Bookings
                                        </MenuItem>
                                        <MenuItem onClick={handleSignOut} className='flex text-navy-blue gap-3 body p-0'>
                                            <img src="/sign_out_icon.svg" alt="" /> Sign out
                                        </MenuItem>
                                    </Menu>
                                </div>
                            ) : (
                                <div id="loggedOut" className="flex flex-row gap-5 items-center justify-end">
                                    <Link to="https://about.venu.lk" target='_blank' className="cursor-pointer px-4">
                                        <div className="body interactive-gradient">
                                            About Us
                                        </div>
                                    </Link>
                                    <Link to="/login" className="cursor-pointer px-4">
                                        <div className="body interactive-gradient">
                                            Login
                                        </div>
                                    </Link>
                                    <Link to="/signup">
                                        <Button btnText='Sign up' classes='!py-2 !px-4 !mt-0 !w-fit' />
                                    </Link>
                                </div>
                            )}
                        </AuthStatus>
                    </div>

                    {/* Mobile Menu - slides in from the top when active */}
                    <div className={`fixed inset-0 bg-dark-blue z-20 transition-transform duration-300 ease-in-out transform ${mobileMenuOpen ? 'translate-y-0' : '-translate-y-full'} hidden lg:block`}>
                        <div className="flex flex-col items-center justify-center h-full gap-8 p-6">
                            <Link to={'/'} onClick={() => setMobileMenuOpen(false)} className="mb-6">
                                <img
                                    className="h-10 w-auto"
                                    loading="lazy"
                                    alt="Venu Logo"
                                    src="/venu-logo.svg"
                                />
                            </Link>
                            <AuthStatus>
                                {(isLoggedIn, firstName) => isLoggedIn ? (
                                    <div className="flex flex-col items-center gap-6 w-full">
                                        <div className="text-xl text-white font-medium">Game On, {firstName}</div>
                                        <Link to="/" onClick={() => setMobileMenuOpen(false)} className="body w-full text-white pt-3">
                                            Home
                                        </Link>
                                        <Link to="https://about.venu.lk" target="_blank" onClick={() => setMobileMenuOpen(false)} className="body w-full text-white">
                                            About Us
                                        </Link>
                                        <Link to="/account" className="body text-white">
                                            Manage Account
                                        </Link>
                                        <Link to="/bookings" className="body text-white">
                                            Your Bookings
                                        </Link>
                                        <div onClick={handleSignOut} className="body text-pink">
                                            Sign out
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center gap-6 w-full">
                                        <Link to="/" onClick={() => setMobileMenuOpen(false)} className="text-lg w-full text-center py-3 hover:bg-gray-100 rounded-lg">
                                            Home
                                        </Link>
                                        <Link to="https://about.venu.lk" target="_blank" onClick={() => setMobileMenuOpen(false)} className="text-lg w-full text-center py-3 hover:bg-gray-100 rounded-lg">
                                            About Us
                                        </Link>
                                        <Link to="/login" onClick={() => setMobileMenuOpen(false)} className="text-lg w-full text-center py-3 hover:bg-gray-100 rounded-lg">
                                            Login
                                        </Link>
                                        <Link to="/signup" onClick={() => setMobileMenuOpen(false)}>
                                            <Button btnText='Sign up' classes='!py-3 !px-6 !mt-0 !w-fit' />
                                        </Link>
                                    </div>
                                )}
                            </AuthStatus>
                        </div>
                    </div>
                </header>
            </div>
        </div>
    );
}

export default Navbar;