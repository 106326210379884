import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import ComingSoon from "./pages/ComingSoon";
import Home from "./pages/Home";
import Listings from "./pages/Listings";
import BookNow from "./pages/BookNow";
import ConfirmBooking from "./pages/ConfirmBooking";
import PlayerSignUp from "./pages/SignUp";
import PlayerLogin from "./pages/Login";
import Account from "./pages/Account";
import Bookings from "./pages/Bookings";
import ForgotPassword from "./pages/ForgotPassword";
import NewPassword from "./pages/NewPassword";
import BookingFinal from "./pages/BookingFinal";
import Privacy from "./pages/Privacy";
import TermsConditions from "./pages/TermsConditions";
import Refund from "./pages/Refund";
import PageNotFound from "./pages/PageNotFound";
import SelectSport from "./pages/SelectSport";
import Timeout from "./pages/Timeout";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/home":
        title = "";
        metaDescription = "";
        break;
      case "/listings":
        title = "";
        metaDescription = "";
        break;
      case "/book-now":
        title = "";
        metaDescription = "";
        break;
      case "/confirm-booking":
        title = "";
        metaDescription = "";
        break;
      case "/account":
        title = "";
        metaDescription = "";
        break;
      case "/bookings":
        title = "";
        metaDescription = "";
        break;
      case "/forgot-password":
        title = "";
        metaDescription = "";
        break;
      case "/new-password":
        title = "";
        metaDescription = "";
        break;
      case "/privacy-policy":
        title = "";
        metaDescription = "";
        break;
      case "/terms-and-conditions":
        title = "";
        metaDescription = "";
        break;
      case "/refunds":
        title = "";
        metaDescription = "";
        break;

    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      {/* <Route path="/" element={<ComingSoon />} /> */}
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<PlayerLogin />} />
      <Route path="/signup" element={<PlayerSignUp />} />
      <Route path="/listings" element={<Listings />} />
      <Route path="/book-now" element={<BookNow />} />
      <Route path="/select-sport" element={<SelectSport />} />
      <Route path="/confirm-booking" element={<ConfirmBooking />} />
      <Route path="/account" element={<Account />} />
      <Route path="/bookings" element={<Bookings />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/new-password" element={<NewPassword />} />
      <Route path="/booking-final" element={<BookingFinal />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/terms-and-conditions" element={<TermsConditions />} />
      <Route path="/refunds" element={<Refund />} />
      <Route path="/timeout" element={<Timeout />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
export default App;
