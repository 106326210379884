// bookingReducer.ts
import dayjs from "dayjs";
import { ActivityListProps } from "../types";

// Action types enum
export enum ActionTypes {
  SET_LOADING = "SET_LOADING",
  SET_INITIAL_DATA = "SET_INITIAL_DATA",
  SET_ACTIVITY = "SET_ACTIVITY",
  SET_DATE = "SET_DATE",
  SET_PRICE = "SET_PRICE",
  SET_TIMINGS = "SET_TIMINGS",
  SET_SELECTED_TIMINGS = "SET_SELECTED_TIMINGS",
  SET_REPEAT_CHECKED = "SET_REPEAT_CHECKED",
  SET_ERROR = "SET_ERROR",
  RESET_SELECTIONS = "RESET_SELECTIONS",
  RESET_TIME_SELECTIONS = "RESET_TIME_SELECTIONS",
  LOAD_STORED_DATA = "LOAD_STORED_DATA",
}

// Timing interface
export interface Timing {
  startTime: string;
  endTime: string;
}

// Location interface
export interface BookingLocationProps {
  name: string;
  address: string;
  contact: string;
  bookingWindow: number;
}

// State interface
export interface BookingState {
  locationId: string;
  activityId: string;
  listing?: BookingLocationProps;
  activityList: ActivityListProps[];
  date: dayjs.Dayjs | null;
  timings: string[];
  selectedTimings: Timing[];
  repeatChecked: boolean;
  error: string | null;
  isLoading: boolean;
  singlePrice: number;
}

// Action interface
interface BookingAction {
  type: ActionTypes;
  payload?: any;
}

// Initial state
export const initialBookingState: BookingState = {
  locationId: "",
  activityId: "",
  listing: undefined,
  activityList: [],
  date: dayjs(),
  timings: [],
  selectedTimings: [],
  repeatChecked: false,
  error: null,
  isLoading: false,
  singlePrice: 0,
};

// Reducer function
export const bookingReducer = (
  state: BookingState,
  action: BookingAction
): BookingState => {
  switch (action.type) {
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case ActionTypes.SET_INITIAL_DATA:
      return {
        ...state,
        listing: action.payload.listing,
        activityList: action.payload.activityList,
      };

    case ActionTypes.SET_ACTIVITY:
      return {
        ...state,
        activityId: action.payload,
      };

    case ActionTypes.SET_DATE:
      return {
        ...state,
        date: action.payload,
      };

    case ActionTypes.SET_PRICE:
      return {
        ...state,
        singlePrice: action.payload,
      };

    case ActionTypes.SET_TIMINGS:
      return {
        ...state,
        timings: action.payload,
      };

    case ActionTypes.SET_SELECTED_TIMINGS:
      return {
        ...state,
        selectedTimings: action.payload,
        error: null, // Clear error when times are selected properly
      };

    case ActionTypes.SET_REPEAT_CHECKED:
      return {
        ...state,
        repeatChecked: action.payload,
      };

    case ActionTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case ActionTypes.RESET_SELECTIONS:
      return {
        ...state,
        date: dayjs(),
        timings: [],
        selectedTimings: [],
        error: null,
      };

    case ActionTypes.RESET_TIME_SELECTIONS:
      return {
        ...state,
        timings: [],
        selectedTimings: [],
        error: null,
      };

    case ActionTypes.LOAD_STORED_DATA:
      return {
        ...state,
        activityId: action.payload.activityId || state.activityId,
        date: action.payload.date || state.date,
        selectedTimings: action.payload.selectedTimings || [],
        repeatChecked: action.payload.repeat || false,
        singlePrice:
          action.payload.totalPrice /
          (action.payload.selectedTimings?.length || 1) /
          (action.payload.repeat ? 4 : 1),
      };

    default:
      return state;
  }
};
