import FooterHome from "../components/FooterHome";
import Navbar from "../components/Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import UserRequest from "../services/userRequest";
import { useEffect, useState } from "react";
import Button from "../components/Button";

export default function BookingFinal() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bookingId, setBookingId] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [booking, setBooking] = useState<any>({});

    useEffect(() => {
        const initializeData = () => {
            const searchParams = new URLSearchParams(location.search);
            const id = searchParams.get("id");
            const storedName = localStorage.getItem('firstName') || '';

            setName(storedName);
            setBookingId(id || "");

            if (id) {
                getBooking(id);
            }
        };

        initializeData();
    }, [location.search]); // Changed dependency to location.search

    const getBooking = async (bookingId: string) => {
        try {
            setIsLoading(true);
            const response = await UserRequest.get(
                `/booking/customer/booking-status?bookingId=${bookingId}`
            );
            setBooking(response.data.data);
        } catch (error) {
            console.error('Error fetching booking:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <div className="flex flex-col justify-between bg-dark-blue min-h-dvh">
                <div className="flex items-center justify-center h-screen">
                    <p className="text-pure-white">Loading...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col justify-between bg-dark-blue min-h-dvh">
            <div className="w-full flex flex-row items-start justify-center pt-14">
                <div className="w-[71rem] h-full flex flex-col items-start justify-center lg:w-full">
                    <Navbar />
                    <div className="flex flex-col gap-8 items-center justify-center w-full px-0 lg:px-5">
                        <img width={250} src="/confirmed.svg" alt="Confirmation" />
                        <div className="flex flex-col gap-4 title-gradient text-center">
                            <p className="body m-0">Hey {name}!</p>
                            <h4 className="m-0">Your Booking #{bookingId} @ {booking.location} is confirmed</h4>
                            <h4 className="m-0">Game on.</h4>
                        </div>
                        <div className="w-[446px] lg:w-full mx-auto self-stretch flex items-center justify-center max-w-full">
                            <div className="flex-1 flex flex-col items-start justify-start gap-2">
                                <Button
                                    btnText="Manage your booking"
                                    click={() => navigate('/bookings')}
                                />
                                <Button
                                    btnText="Return to home"
                                    classes="!mt-0"
                                    click={() => navigate('/')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterHome />
        </div>
    );
}