import FooterHome from "../components/FooterHome";
import Navbar from "../components/Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import UserRequest from "../services/userRequest";
import { useEffect, useState } from "react";
import Button from "../components/Button";

export default function Timeout() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bookingId, setBookingId] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [booking, setBooking] = useState<any>({});

    useEffect(() => {
        // Clear local storage when the component mounts
        localStorage.clear();
    }, []);

    if (isLoading) {
        return (
            <div className="flex flex-col justify-between bg-dark-blue min-h-dvh">
                <div className="flex items-center justify-center h-screen">
                    <p className="text-pure-white">Loading...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col justify-between bg-dark-blue min-h-dvh">
            <div className="w-full flex flex-row justify-center pt-14">
                <div className="h-full flex flex-col items-start justify-center lg:w-full">
                    <Navbar />
                    <div className="flex flex-col grow gap-8 items-center justify-center w-full px-0 lg:px-5 mt-40">
                        <img width={250} src="/timeout.svg" alt="Confirmation" />
                        <div className="flex flex-col gap-4 title-gradient text-center">
                            <h2 className="m-0">401: Your session timed out</h2>
                            <p className="body m-0">Contact +94 77 462 2736 for help</p>
                        </div>
                        <div className="w-[446px] lg:w-full mx-auto self-stretch flex items-center justify-center max-w-full">
                            <div className="flex-1 flex flex-col items-start justify-start gap-2">
                                <Button
                                    btnText="Return to home"
                                    classes="!mt-0"
                                    click={() => navigate('/')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterHome />
        </div>
    );
}