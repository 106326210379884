import { FunctionComponent, useEffect, useState } from "react";
import BookingHeader from "../components/BookingHeader";
import BookingSlider from "../components/BookingSlider";
import FooterHome from "../components/FooterHome";
import SportSelection from "../components/SportSelection";
import SelectBooking from "../components/SelectBooking";
import { useSearchParams } from "react-router-dom";
import AuthRequest from "../services/authRequest";
import Navbar from "../components/Navbar";

interface BookingLocationProps {
  name: string,
  address: string,
  contact: string,
}

const BookNow: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const [listing, setListing] = useState<BookingLocationProps>()
  const [searchVal, setSearchVal] = useState(searchParams.get('locationId'));
  const [activityId, setActivityId] = useState(searchParams.get('activityId'));

  const getLocationBySearch = async () => {
    try {
      const response = await AuthRequest.get(`Location/customer/location-id?id=${searchVal}`)
      console.log(response.data);
      setListing(response.data.data);
    } catch (error) {
      console.log('error', error);
    }
  }

  useEffect(() => {
    getLocationBySearch();
  }, [searchVal])

  return (
    <div className="w-full relative bg-dark-blue overflow-hidden flex flex-col items-start justify-start pt-[3.25rem] px-[0rem] pb-[0rem] box-border gap-[1.75rem_0rem] tracking-[normal]">
      <Navbar />
      <div className="w-[71rem] lg:w-full mx-auto">
        <div className="w-7/12 lg:w-full">
          <BookingHeader name={listing ? listing.name : ''} address={listing ? listing?.address : ''} contact={listing ? listing?.contact : ''} />
          <BookingSlider id={searchVal ? searchVal : ''} />
          <main className="w-full">
            <section className="w-full flex flex-col items-start justify-start">
              <SportSelection />
              {/* <SelectBooking /> */}
            </section>
          </main>
        </div>
      </div>
      <FooterHome />
    </div>
  );
};

export default BookNow;
