// DateSelector.tsx
import React from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";

interface DateSelectorProps {
    date: Dayjs | null;
    onChange: (date: Dayjs | null) => void;
    maxDate: Dayjs;
    isLoading: boolean;
    disabled?: boolean;
}

// Theme for the date picker
const theme = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#ff69b4', // Pink color for icons
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                    color: 'white', // Text color for the input field
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white', // Border color for the input field
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white', // Border color on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white', // Border color when focused
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: 'white', // Label color
                    '&.Mui-focused': {
                        color: 'white', // Label color when focused
                    },
                },
            },
        },
    },
});

const DateSelector: React.FC<DateSelectorProps> = ({
    date,
    onChange,
    maxDate,
    isLoading,
    disabled = false // Default to false
}) => {
    return (
        <div className="calendar w-full flex flex-col flex-0 gap-8">
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        {isLoading ? (
                            <div className="flex items-center justify-center p-3 min-w-48">
                                <CircularProgress size={24} sx={{ color: "white" }} />
                            </div>
                        ) : (
                            <DatePicker
                                label="Select Date"
                                className="calendar-pick"
                                value={date}
                                onChange={(newDate) => {
                                    console.log("Date selected:", newDate?.format("YYYY-MM-DD")); // Debug log
                                    onChange(newDate);
                                }}
                                format="DD/MM/YYYY"
                                disablePast
                                disabled={disabled || isLoading} // Disable if explicitly disabled or loading
                                maxDate={maxDate}
                                sx={{
                                    width: "100%",
                                    borderRadius: "12px",
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        sx: {
                                            ".MuiInputLabel-root": {
                                                color: disabled ? "#888888" : "#ffffff",
                                            },
                                            ".MuiOutlinedInput-root": {
                                                borderRadius: "12px",
                                                "&.Mui-disabled": {
                                                    "& > fieldset": {
                                                        borderColor: "#888888 !important",
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        WebkitTextFillColor: "#888888",
                                                    },
                                                },
                                                "& > fieldset": {
                                                    borderColor: "#ffffff",
                                                },
                                                "&:hover > fieldset": {
                                                    borderColor: "#ffffff",
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#ffffff !important",
                                                },
                                                ".MuiInputBase-input": {
                                                    color: "#ffffff",
                                                },
                                                ".MuiIconButton-root": {
                                                    color: "#ff69b4",
                                                },
                                            },
                                        },
                                    },
                                }}
                            />
                        )}
                    </DemoContainer>
                </LocalizationProvider>
            </ThemeProvider>
        </div>
    );
};

export default React.memo(DateSelector);