// BookingSummary.tsx
import React from "react";

interface BookingSummaryProps {
    totalPrice: number;
    bookings: {
        activityName: string;
        date: string;
        times: string;
        price: number;
    }[];
}

const BookingSummary: React.FC<BookingSummaryProps> = ({
    totalPrice,
    bookings
}) => {
    return (
        <div className="booking-summary flex flex-col lg:items-center gap-6">
            {/* Show each booking summary */}
            {/* {bookings.length > 0 && (
                <div className="booking-details text-white">
                    <h4 className="mb-4">Booking Summary</h4>

                    <div className="flex flex-col gap-4">
                        {bookings.map((booking, index) => (
                            booking.activityName && (
                                <div key={index} className="booking-item bg-gravel1 p-4 rounded-lg">
                                    <div className="flex justify-between items-center mb-2">
                                        <h5 className="font-medium">{booking.activityName}</h5>
                                        <span className="text-right">{booking.price.toLocaleString()} LKR</span>
                                    </div>
                                    <div className="text-sm opacity-80">
                                        <div>{booking.date}</div>
                                        <div>{booking.times}</div>
                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                </div>
            )} */}

            {/* Total Cost Display */}
            <div className="flex items-center gap-[25px] text-white">
                <p className="mt-4 title-gradient label2">Total Cost</p>
                <h2 className="m-0">
                    {totalPrice.toLocaleString()} LKR
                </h2>
            </div>
        </div>
    );
};

export default React.memo(BookingSummary);