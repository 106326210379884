// bookingService.ts
import AuthRequest from "./authRequest";
import { ActivityListProps, TimeSlot } from "../types";
import { BookingLocationProps } from "../state/bookingReducer";

/**
 * Fetches location details by ID
 */
export async function fetchLocation(
  locationId: string
): Promise<BookingLocationProps> {
  try {
    const response = await AuthRequest.get(
      `Location/customer/location-id?id=${locationId}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching location:", error);
    throw new Error("Failed to fetch location details");
  }
}

/**
 * Fetches activities available at a location
 */
export async function fetchActivities(
  locationId: string
): Promise<ActivityListProps[]> {
  try {
    const response = await AuthRequest.get(
      `Location/customer/activities-by-location?id=${locationId}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching activities:", error);
    throw new Error("Failed to fetch activities");
  }
}

/**
 * Fetches available time slots for a selected date and activity
 */
export async function fetchAvailableTimes(
  locationId: string,
  activityId: string,
  date: string
): Promise<TimeSlot[]> {
  try {
    console.log("API call with date:", date); // Debug log

    const requestBody = {
      locationId,
      activityId,
      date, // Make sure this is in YYYY-MM-DD format
    };

    console.log("Request body:", requestBody); // Debug log

    const response = await AuthRequest.post(
      "Location/customer/available-times",
      requestBody
    );

    console.log("API response:", response.data); // Debug log

    return response.data.data;
  } catch (error) {
    console.error("Error fetching available times:", error);
    throw error;
  }
}
