// Main Component File: SelectSport.tsx
import { FunctionComponent, useCallback, useEffect, useMemo, useReducer } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast } from "react-toastify";

// Components
import Navbar from "../components/Navbar";
import FooterHome from "../components/FooterHome";
import BookingHeader from "../components/BookingHeader";
import BookingSlider from "../components/BookingSlider";
import Button from "../components/Button";
import ActivitySelector from "../components/ActivitySelector";
import DateSelector from "../components/DateSelector";
import TimeSlotSelector from "../components/TimeSlotSelector";
import BookingSummary from "../components/BookingSummary";

// Services and Utilities
import { fetchLocation, fetchActivities, fetchAvailableTimes } from "../services/bookingService";
import {
  formatTimeIntervals,
  getNextThreeWeeks,
  findEarliestAndLatestTimes,
  checkTimingContinuity
} from "../state/timeUtils";
import { BookingState, bookingReducer, initialBookingState } from "../state/bookingReducer";
import { ActionTypes } from "../state/bookingReducer";

// Types
import { ActivityListProps, TimeInterval } from "../types";

// Initialize dayjs plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const SelectSport: FunctionComponent = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const today = dayjs();
  const maxDate = dayjs().add(2, "month");

  // Use reducer instead of multiple useState calls
  const [state, dispatch] = useReducer(bookingReducer, {
    ...initialBookingState,
    locationId: searchParams.get("locationId") || "",
    activityId: searchParams.get("activityId") || ""
  });

  // Destructure state for easier access
  const {
    locationId,
    activityId,
    listing,
    activityList,
    date,
    timings,
    selectedTimings,
    repeatChecked,
    error,
    isLoading,
    singlePrice
  } = state;

  // Derived/calculated values using useMemo to prevent unnecessary recalculations
  const totalPrice = useMemo(() =>
    selectedTimings.length * singlePrice * (repeatChecked ? 4 : 1),
    [selectedTimings.length, singlePrice, repeatChecked]
  );

  const repeatedDates = useMemo(() =>
    repeatChecked ? getNextThreeWeeks(date) : [date?.format("DD MMM") ?? ""],
    [repeatChecked, date]
  );

  // Load initial data from localStorage if in edit mode
  useEffect(() => {
    const isEditMode = searchParams.get("edit") === "true";
    if (isEditMode) {
      try {
        const storedData = JSON.parse(localStorage.getItem("bookingData") || "{}");
        if (Object.keys(storedData).length > 0) {
          dispatch({
            type: ActionTypes.LOAD_STORED_DATA,
            payload: {
              ...storedData,
              date: storedData.date ? dayjs(storedData.date) : today
            }
          });
        }
      } catch (error) {
        toast.error("Failed to load previous booking data");
        console.error("Error loading stored booking data:", error);
      }
    }
  }, [searchParams]);

  // Fetch location and activities data
  useEffect(() => {
    const loadInitialData = async () => {
      if (!locationId) return;

      dispatch({ type: ActionTypes.SET_LOADING, payload: true });

      try {
        const [locationData, activitiesData] = await Promise.all([
          fetchLocation(locationId),
          fetchActivities(locationId)
        ]);

        dispatch({
          type: ActionTypes.SET_INITIAL_DATA,
          payload: {
            listing: locationData,
            activityList: activitiesData
          }
        });
      } catch (error) {
        toast.error("Failed to load location data");
        console.error("Error fetching initial data:", error);
      } finally {
        dispatch({ type: ActionTypes.SET_LOADING, payload: false });
      }
    };

    loadInitialData();
  }, [locationId]);

  // Update price when activity changes
  useEffect(() => {
    if (activityId && activityList.length > 0) {
      const selectedActivity = activityList.find(
        (activity) => activity.id === Number(activityId)
      );

      if (selectedActivity) {
        dispatch({
          type: ActionTypes.SET_PRICE,
          payload: selectedActivity.price
        });
      }
    }
  }, [activityId, activityList]);

  // Fetch available time slots when date or activity changes
  useEffect(() => {
    const loadTimeSlots = async () => {
      if (!date || !activityId || activityId === "0") return;

      dispatch({ type: ActionTypes.SET_LOADING, payload: true });

      try {
        // Ensure we're using the selected date, not today
        const formattedDate = date.format("YYYY-MM-DD");
        console.log("Fetching timeslots for date:", formattedDate); // Debug log

        const availableTimes = await fetchAvailableTimes(locationId, activityId, formattedDate);
        const formattedTimes = formatTimeIntervals(availableTimes);

        console.log("Received timeslots:", formattedTimes); // Debug log

        dispatch({
          type: ActionTypes.SET_TIMINGS,
          payload: formattedTimes
        });
      } catch (error) {
        toast.error("Failed to load available time slots");
        dispatch({ type: ActionTypes.SET_TIMINGS, payload: [] });
      } finally {
        dispatch({ type: ActionTypes.SET_LOADING, payload: false });
      }
    };

    loadTimeSlots();
  }, [date, activityId, locationId]);

  // Event handlers with useCallback to prevent unnecessary recreations
  const handleActivityChange = useCallback((newActivityId: string) => {
    dispatch({ type: ActionTypes.SET_ACTIVITY, payload: newActivityId });

    // Reset other selections when activity changes
    dispatch({ type: ActionTypes.RESET_SELECTIONS });
  }, []);

  const handleDateChange = useCallback((newDate: dayjs.Dayjs | null) => {
    // Only update if a valid date is selected
    if (newDate) {
      dispatch({ type: ActionTypes.SET_DATE, payload: newDate });
      dispatch({ type: ActionTypes.RESET_TIME_SELECTIONS });

      // Removing this empty function as we're relying on the useEffect dependency to trigger API call
    }
  }, []);

  const handleTimeSelectionChange = useCallback((selectedTimes: string[]) => {
    // Convert string time slots to timing objects
    const newTimings = selectedTimes.map(timeString => {
      const [startTime, endTime] = timeString.split(" - ");
      return { startTime, endTime };
    });

    // Sort by start time
    newTimings.sort((a, b) => {
      return a.startTime.localeCompare(b.startTime);
    });

    // Check if times are continuous
    const isContinuous = checkTimingContinuity(newTimings);

    if (!isContinuous) {
      toast.error("Please select continuous time slots");
      return;
    }

    dispatch({
      type: ActionTypes.SET_SELECTED_TIMINGS,
      payload: newTimings
    });
  }, []);

  const handleRepeatBookingChange = useCallback((checked: boolean) => {
    dispatch({
      type: ActionTypes.SET_REPEAT_CHECKED,
      payload: checked
    });
  }, []);

  const handleConfirmBooking = useCallback(() => {
    if (selectedTimings.length === 0 || error) {
      toast.error("Please select valid time slots");
      return;
    }

    const { earliest, latest } = findEarliestAndLatestTimes(selectedTimings);
    const selectedActivity = activityList.find(
      (activity) => activity.id === Number(activityId)
    );

    const bookingData = {
      earliest,
      latest,
      selectedDates: repeatChecked
        ? repeatedDates
        : [date?.format("DD-MM-YYYY") ?? ""],
      sportTypeName: selectedActivity?.typeName || "",
      courtName: selectedActivity?.name || "",
      selectedTimings,
      totalPrice,
      activityId,
      locationId,
      repeat: repeatChecked,
      date,
    };

    localStorage.setItem("bookingData", JSON.stringify(bookingData));
    navigate(`/confirm-booking?locationId=${locationId}&activityId=${activityId}`);
  }, [
    selectedTimings,
    error,
    activityList,
    activityId,
    repeatChecked,
    repeatedDates,
    date,
    totalPrice,
    locationId,
    navigate
  ]);

  return (
    <div className="w-full relative bg-dark-blue overflow-hidden flex flex-col items-start justify-start pt-[3.25rem] px-[0rem] pb-[0rem] box-border gap-[1.75rem_0rem] tracking-[normal]">
      <Navbar />
      <div className="w-[71rem] lg:w-full mx-auto">
        <div className="w-full lg:w-full">
          {/* Location header */}
          <BookingHeader
            name={listing?.name || ''}
            address={listing?.address || ''}
            contact={listing?.contact || ''}
          />

          {/* Location slider */}
          <BookingSlider id={locationId} />

          <form className="flex flex-col gap-14 lg:px-5">
            <div>
              <h4 className="title-gradient mn-0">Add a Booking</h4>
              <hr className="w-full border border-[#7D7D7D] m-0" />
            </div>

            <div>
              <p className="label2 title-gradient">Select activity</p>

              <div className="flex gap-4 items-center justify-between">
                {/* Activity selection */}
                <ActivitySelector
                  activities={activityList}
                  selectedId={activityId || "0"}
                  onChange={handleActivityChange}
                  price={singlePrice}
                  activityType={activityList.find(
                    (activity) => activity.id === Number(activityId)
                  )?.typeName}
                  isLoading={isLoading}
                />

                {/* Date selection */}
                <DateSelector
                  date={date}
                  onChange={(newDate) => {
                    // Ensure we're passing a proper Dayjs object
                    console.log("Parent received date change:", newDate?.format("YYYY-MM-DD"));
                    handleDateChange(newDate);
                  }}
                  maxDate={maxDate}
                  isLoading={isLoading}
                />

                {/* Time slot selection */}
                {/* <TimeSlotSelector
                  timings={timings}
                  selectedTimings={selectedTimings}
                  onChange={handleTimeSelectionChange}
                  error={error}
                  isLoading={isLoading}
                /> */}
              </div>
            </div>

            {/* Repeat booking option */}
            <div className="flex items-center text-white">
              <label className="flex items-center gap-2 cursor-pointer">
                <input
                  type="checkbox"
                  checked={repeatChecked}
                  onChange={(e) => handleRepeatBookingChange(e.target.checked)}
                  className="form-checkbox h-5 w-5"
                />
                <span>Repeat weekly for 4 weeks</span>
              </label>
            </div>

            {/* Booking summary */}
            {/* <BookingSummary
              totalPrice={totalPrice}
              repeatedDates={repeatedDates}
              isRepeat={repeatChecked}
            /> */}

            {/* Confirm booking section */}
            <div className="confirm flex flex-col gap-4">
              <Button
                btnText="Confirm Booking"
                disabled={totalPrice === 0 || !!error || isLoading}
                click={handleConfirmBooking}
              />
              <div className="w-[24rem] text-white">
                The next step is to choose your payment method.
              </div>
            </div>
          </form>
        </div>
      </div>
      <FooterHome />
    </div>
  );
};

export default SelectSport;