import React from "react";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { ActivityListProps } from "../types";
import CircularProgress from "@mui/material/CircularProgress";

interface ActivitySelectorProps {
    activities: ActivityListProps[];
    selectedId: string;
    onChange: (id: string) => void;
    price: number;
    activityType?: string;
    isLoading: boolean;
}

const ActivitySelector: React.FC<ActivitySelectorProps> = ({
    activities,
    selectedId,
    onChange,
    price,
    activityType,
    isLoading
}) => {
    const handleChange = (e: SelectChangeEvent<string>) => {
        onChange(e.target.value);
    };

    return (
        <div className="col-span-2 lg:col-span-1 sport-dropdown flex flex-col flex-1 gap-5 w-full">
            <div className="w-full flex lg:flex-col items-center lg:items-start justify-end gap-8 lg:gap-4 ">
                <FormControl
                    className="flex-1 lg:flex-auto flex justify-center w-full"
                    variant="outlined"
                    sx={{
                        border: "1px solid #fff",
                        borderRadius: "16px",
                    }}
                >
                    {isLoading ? (
                        <div className="flex items-center justify-center p-3">
                            <CircularProgress size={24} sx={{ color: "white" }} />
                        </div>
                    ) : (
                        <Select
                            disableUnderline
                            displayEmpty
                            value={selectedId}
                            onChange={handleChange}
                            aria-label="Select activity"
                            sx={{
                                color: "#fff",
                                backgroundColor: "transparent",
                                borderRadius: "16px",
                                "& .MuiSelect-icon": {
                                    color: "#FB709A",
                                    border: "1px solid #FB709A",
                                    borderRadius: "16px",
                                    marginRight: "8px",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none"
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "none"
                                },
                                "& .MuiSelect-select": {
                                    color: "#fff"
                                },
                                "& .Mui-disabled:not(.Mui-selected)": {
                                    color: "#424F6F"
                                }
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: "#1F2B49",
                                        color: "#fff",
                                        borderRadius: "16px",
                                        border: "1px solid #fff",
                                        "& .MuiMenuItem-root": {
                                            "&.Mui-selected": {
                                                background: "linear-gradient(45deg, #e7fff0 0%, #feffff 49%, #c0d6e1 100%)",
                                                color: "#1F2B49",
                                            },
                                            "&:hover": {
                                                background: "#fff",
                                                color: "#1F2B49",
                                            },
                                            "&.Mui-selected:hover": {
                                                background: "linear-gradient(45deg, #e7fff0 0%, #feffff 49%, #c0d6e1 100%)",
                                                color: "#1F2B49",
                                            },
                                        }
                                    }
                                }
                            }}
                        >
                            <MenuItem value="0" disabled>Select Court</MenuItem>
                            {activities.map((sport) => (
                                <MenuItem key={sport.id} value={sport.id.toString()}>
                                    {sport.name}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </FormControl>

                <div className="flex items-center gap-4 lg:w-full">
                    <div className="min-w-28 rounded-2xl flex flex-1 flex-row justify-end text-white bg-gravel1 h-12 items-center px-6">
                        {/* If price is 0 then it should be xxxx */}
                        {price.toLocaleString()} /hr
                    </div>
                    <div className="flex justify-center items-center">
                        {activityType ? (
                            <img
                                className="flex items-center"
                                src={`/sportTypes/${activityType.toLowerCase()}.png`}
                                height={40}
                                alt={`${activityType} icon`}
                            />
                        ) :
                            <img
                                className="flex items-center invisible"
                                src={`/sportTypes/basketball.png`}
                                height={40}
                                alt={`${activityType} icon`}
                            />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ActivitySelector);