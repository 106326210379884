import { FunctionComponent, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface BookingLocationProps {
  name?: string,
  address?: string,
  contact?: string,
}

const BookingHeader = ({ name, address, contact }: BookingLocationProps) => {
  const navigate = useNavigate();
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    setSearchVal(localStorage.getItem('searchVal') || '')
  })

  return (
    <div className="w-full self-stretch flex flex-row items-start justify-start lg:px-[1.25rem]">
      <div className="flex flex-col items-start justify-start">
        <div className="self-stretch flex flex-col items-start justify-start gap-[4.169rem_0rem] mq750:gap-[1.063rem_0rem] mq1125:gap-[2.063rem_0rem]">
          <div className="flex flex-row items-start justify-start gap-[0rem_1rem]">
            <div className="flex flex-col items-start justify-start pt-[0.375rem] px-[0rem] pb-[0rem]">
              <img
                onClick={() => navigate(`/listings?searchVal=${searchVal}`)}
                className="cursor-pointer w-[1.5rem] h-[1.5rem] relative overflow-hidden shrink-0 object-contain"
                loading="lazy"
                alt=""
                src="/ggchevrondowno1@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="flex mt-6 flex-col items-start justify-start">
          <h2 className="m-0 title-gradient mb-2">
            {name}
          </h2>
          <div className="flex flex-row gap-6 items-center justify-start">
            <div className="flex gap-2">
              <img src="/map_icon.svg" alt="" />
              <p className="relative body m-0 text-white">
                {address}
              </p>
            </div>
            <div className="flex gap-2">
              <img src="/phstarfill.svg" alt="" />
              <p className="relative body m-0 text-white">
                0
              </p>
            </div>
            <div className="flex gap-2">
              <img src="/contact_icon.svg" alt="" />
              <p className="relative body m-0 text-white">
                {contact}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingHeader;
