// TimeSlotSelector.tsx
import React, { useState, useEffect } from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
    FormHelperText,
    CircularProgress,
    Typography
} from "@mui/material";
import { FormattedTimeSlot, ensureTimeIs12HourFormat } from "../state/timeUtils";

// Update the interface to include occupiedTimeSlots
interface TimeSlotSelectorProps {
    timeSlots: FormattedTimeSlot[];
    selectedTimings: { startTime: string; endTime: string }[];
    onChange: (selectedTimes: string[]) => void;
    error: string | null;
    isLoading: boolean;
    occupiedTimeSlots: string[]; // Slots selected in other bookings
}

const TimeSlotSelector: React.FC<TimeSlotSelectorProps> = ({
    timeSlots,
    selectedTimings,
    onChange,
    error,
    isLoading,
    occupiedTimeSlots
}) => {
    // Convert Timing objects to strings for the select component
    const [selectedTimeStrings, setSelectedTimeStrings] = useState<string[]>([]);

    // Update selectedTimeStrings when selectedTimings changes
    useEffect(() => {
        // Make sure all times are in 12-hour format for display
        const timeStrings = selectedTimings.map(
            timing => `${ensureTimeIs12HourFormat(timing.startTime)} - ${ensureTimeIs12HourFormat(timing.endTime)}`
        );
        setSelectedTimeStrings(timeStrings);
    }, [selectedTimings]);

    const handleTimeSelectionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newSelection = event.target.value as string[];
        onChange(newSelection);
    };

    // Format occupied time slots to ensure they're in 12-hour format for comparison
    const formattedOccupiedSlots = occupiedTimeSlots.map(slot => {
        const [start, end] = slot.split(" - ");
        return `${ensureTimeIs12HourFormat(start)} - ${ensureTimeIs12HourFormat(end)}`;
    });

    if (timeSlots.length === 0 && !isLoading) {
        return (
            <div className="flex gap-2 text-white min-w-48">
                <p className="label1">No timings available for this day</p>
            </div>
        );
    }

    return (
        <div className="flex flex-col min-w-full w-full gap-6">
            <div className="select-timings gap-4 flex w-full mt-2">
                <FormControl className="w-full">
                    <InputLabel
                        htmlFor="time"
                        sx={{
                            color: "#fff",
                            fontSize: "16px",
                            fontWeight: "500",
                            "&.Mui-focused": {
                                color: "#fff"
                            }
                        }}
                    >
                        Select Time
                    </InputLabel>

                    {isLoading ? (
                        <div className="flex items-center justify-center p-4">
                            <CircularProgress size={24} sx={{ color: "white" }} />
                        </div>
                    ) : (
                        <Select
                            color="primary"
                            name="time"
                            id="time"
                            multiple
                            label="Select Time"
                            placeholder="Select Time"
                            value={selectedTimeStrings}
                            onChange={handleTimeSelectionChange as any}
                            input={<OutlinedInput label="Select Time" />}
                            renderValue={(selected) => (selected as string[]).join(", ")}
                            sx={{
                                borderRadius: "16px",
                                color: "#fff",
                                backgroundColor: "transparent",
                                textAlign: "left",
                                "& .MuiSelect-icon": {
                                    color: "#FB709A",
                                    border: "1px solid #FB709A",
                                    borderRadius: "16px",
                                    marginRight: "8px",
                                },
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff',
                                    borderRadius: "16px",
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#fff',
                                },
                                "& .Mui-disabled": {
                                    color: "#424F6F"
                                }
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: "#1F2B49",
                                        color: "#fff",
                                        borderRadius: "16px",
                                        border: "1px solid #fff",
                                        "& .MuiMenuItem-root": {
                                            "&.Mui-selected": {
                                                background: "linear-gradient(45deg, #e7fff0 0%, #feffff 49%, #c0d6e1 100%)",
                                                color: "#1F2B49",
                                            },
                                            "&:hover:not(.Mui-disabled)": {
                                                background: "#fff",
                                                color: "#1F2B49",
                                            },
                                            "&:hover .MuiCheckbox-root": {
                                                color: "#FB709A"
                                            },
                                            "&.Mui-disabled": {
                                                color: "#424F6F",
                                                opacity: 0.7
                                            }
                                        },
                                        "& .MuiCheckbox-root": {
                                            color: "#fff",
                                            "&.Mui-checked": {
                                                color: "#FB709A"
                                            },
                                            "&.Mui-disabled": {
                                                color: "#424F6F"
                                            }
                                        }
                                    }
                                }
                            }}
                        >
                            {timeSlots.map((slot, id) => {
                                // Check if this slot is occupied in another booking
                                const isOccupiedInOtherBooking = formattedOccupiedSlots.includes(slot.timeString);

                                return (
                                    <MenuItem
                                        key={id}
                                        value={slot.timeString}
                                        disabled={slot.isOccupied || slot.isClosed || isOccupiedInOtherBooking}
                                        sx={{
                                            opacity: slot.isOccupied || isOccupiedInOtherBooking ? 0.6 : 1,
                                            "&.Mui-disabled": {
                                                color: "#424F6F",
                                            }
                                        }}
                                    >
                                        <Checkbox
                                            checked={selectedTimeStrings.includes(slot.timeString)}
                                            disabled={slot.isOccupied || slot.isClosed || isOccupiedInOtherBooking}
                                        />
                                        <ListItemText
                                            primary={slot.timeString}
                                            secondary={
                                                slot.isOccupied
                                                    ? "Occupied"
                                                    : (slot.isClosed
                                                        ? "Closed"
                                                        : (isOccupiedInOtherBooking
                                                            ? "Selected in another booking"
                                                            : ""))
                                            }
                                            primaryTypographyProps={{
                                                sx: {
                                                    color: "inherit"
                                                }
                                            }}
                                            secondaryTypographyProps={{
                                                sx: {
                                                    color: "#FB709A",
                                                    opacity: 0.8
                                                }
                                            }}
                                        />
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    )}

                    {error && (
                        <FormHelperText error>
                            {error}
                        </FormHelperText>
                    )}
                </FormControl>
            </div>
        </div>
    );
};

export default React.memo(TimeSlotSelector);