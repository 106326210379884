import { FunctionComponent } from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

interface BookingSliderProps {
  id: string;
}

const BookingSlider: FunctionComponent<BookingSliderProps> = ({ id }) => {
  // Create an array of image paths instead of repeating code
  const images = Array.from({ length: 23 }, (_, i) => ({
    src: `serendib/${i + 1}.webp`,
    alt: `Image ${i + 1}`
  }));

  return (
    <section className="self-stretch flex flex-col items-center pt-6 lg:px-5 pb-[4.625rem] mq450:pb-12">
      <div className="w-full max-w-full">
        <Splide
          options={{
            autoplay: true,
            arrows: false,
            type: 'loop',
            interval: 4000,
            gap: 20,
          }}
          aria-label="Gallery Images"
        >
          {images.map((image, index) => (
            <SplideSlide key={index}>
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-[400px] object-cover rounded-2xl"
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </section>
  );
};

export default BookingSlider;