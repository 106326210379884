import { FunctionComponent, useEffect, useState } from "react"
import AuthRequest from "../services/authRequest"
import { Link } from "react-router-dom"

interface LocationType {
    id: number
    name: string
    courts: CourtType[]
    address: string
    city: string | null
    mapLink: string
    contact: string
    leadTime: number
    openTime: string
    closeTime: string
}

interface CourtType {
    id: number
    name: string
    isActive: boolean
    activityList: ActivityType[]
}

interface ActivityType {
    id: number
    nickname: string
    price: number
    type: number
    typeName: string
    isDeleted: boolean
}

const FeaturedLocation: FunctionComponent = () => {
    const [location, setLocation] = useState<LocationType | null>(null)

    const fetchLatestLocation = async () => {
        try {
            const response = await AuthRequest.get("/Location/customer/location-latest")
            const data = response.data.data[0] // Get the first location
            setLocation(data)
        } catch (error) {
            console.error("Error fetching locations:", error)
        }
    }

    useEffect(() => {
        fetchLatestLocation()
    }, [])

    if (!location) return null

    // Collect all activities from all active courts
    const allActivities = location.courts
        .filter(court => court.isActive)
        .flatMap(court => 
            court.activityList
                .filter(activity => !activity.isDeleted)
                .map(activity => ({
                    ...activity,
                    courtId: court.id // Add courtId to each activity for reference
                }))
        )

    return (
        <section className="relative w-10/12 lg:w-full mb-16 pr-5">
            <p className="label2 title-gradient">Featured Court</p>
            <div className="bg-glass p-6 flex flex-col gap-8 text-white rounded-2xl">
                <div className="title">
                    <h3 className="title-gradient m-0">{location.name}</h3>
                </div>
                <div className="details flex flex-col gap-4">
                    <p className="body m-0 title-gradient">Book now and get your game on</p>
                    
                    {allActivities.length > 0 && (
                        <>
                            <p className="label2 uppercase m-0">Select</p>
                            <div className="grid grid-cols-2 lg:grid-cols-1 gap-4">
                                {allActivities.map((activity) => (
                                    <div
                                        key={activity.id}
                                        className="w-full courtFull border border-[#3B4765] border-solid bg-glass px-4 py-1 flex gap-2 rounded-lg items-center group"
                                    >
                                        <img
                                            className="h-10 w-10 object-cover"
                                            loading="lazy"
                                            alt={`${activity.typeName} icon`}
                                            src={`/sportTypes/${activity.typeName.toLowerCase()}.png`}
                                        />
                                        <Link
                                            to={`/select-sport?locationId=${location.id}&activityId=${activity.id}`}
                                            className="w-full"
                                        >
                                            <div className="w-full details flex flex-1 lg:flex-col justify-between items-center lg:items-start">
                                                <h5 className="title-gradient group-hover:text-navy-blue my-0">
                                                    {activity.nickname}
                                                </h5>
                                                <p className="body price title-gradient my-0">
                                                    {activity.price} /hr
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <img 
                className="w-fit absolute top-0 -right-8 lg:right-0" 
                src="/featured-court.png" 
                alt="Featured court" 
            />
        </section>
    )
}

export default FeaturedLocation