import { FunctionComponent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UserRequest from "../services/userRequest";
import FeaturedLocation from "./FeaturedLocation";

const HeaderHome: FunctionComponent = () => {
  const [searchVal, setSearchVal] = useState('');
  const navigate = useNavigate();

  const getAllSports = async () => {
    try {
      const response = await UserRequest.get('Location/activity-all');
      const data = response.data.data;
      console.log('data', data);
    } catch (error) {
      console.log('error', error);
    }
  }

  const sports = [
    {
      name: 'Basketball',
      icon: 'sportTypes/basketball.png'
    },
    {
      name: 'Badminton',
      icon: 'sportTypes/badminton.png'
    },
    {
      name: 'Cricket',
      icon: 'sportTypes/cricket.png'
    },
    {
      name: 'Futsal',
      icon: 'sportTypes/futsal.png'
    },
    {
      name: 'Rugby',
      icon: 'sportTypes/rugby.png'
    },
    {
      name: 'Table Tennis',
      icon: 'sportTypes/table tennis.png'
    },
    {
      name: 'Tennis',
      icon: 'sportTypes/tennis.png'
    },
    {
      name: 'Exercise',
      icon: 'sportTypes/exercise.png'
    },
    {
      name: 'Netball',
      icon: 'sportTypes/netball.png'
    },
    {
      name: 'Karate',
      icon: 'sportTypes/karate.png'
    },
  ]

  const neighborhood = [
    {
      name: 'Bambalapitiya',
      image: 'bamba.png',
    },
    {
      name: 'Wellawatte',
      image: 'wellawatte.png',
    },
    {
      name: 'Kollupitiya',
      image: 'kollupitiya.png',
    },
    {
      name: 'Dehiwala',
      image: 'dehiwala.png',
    }
  ]

  const handleSearch = () => {
    if (searchVal == '') {
      toast.error('Search Field is empty')
    } else {
      navigate(`/listings?searchVal=${searchVal}`);
    }
  }

  // useEffect(() => {
  //   getAllSports();
  // }, [])

  return (
    <section className="w-full flex flex-col items-center justify-start pb-[2rem] pl-[1.25rem] max-w-full mt-[2rem]">
      <div className="lg:w-full w-[71rem] flex flex-row flex-wrap items-start justify-start pr-[1.25rem]">
        <div className="flex-1 flex flex-col items-start justify-start">
          <div className="lg:w-full w-10/12 flex flex-col items-start justify-start gap-[3.991rem_0rem]">
            <h1 className="m-0 relative title-gradient">
              Find a court that plays to your strengths.
            </h1>
            <form onSubmit={handleSearch} className="w-full relative" action="">
              <p className="label2 title-gradient">Find a place to play</p>
              <input
                placeholder="Enter name or location"
                value={searchVal}
                onChange={(e) => { setSearchVal(e.target.value) }}
              />
              <img
                width="24px"
                height="24px"
                src="/search-pink.svg"
                className="cursor-pointer absolute top-[56px] right-6"
                onClick={handleSearch}
              />
            </form>
          </div>
        </div>
      </div>
      <div className="lg:w-full w-[71rem] flex flex-col items-start justify-start pt-[5rem] pb-[6.025rem]">
        <FeaturedLocation />
        <div className="flex flex-col items-start justify-start pr-[1.25rem] gap-8">
          <h2 className="title-gradient m-0">
            View by sport
          </h2>
          <div className="self-stretch flex flex-row items-start justify-start">
            <div className="w-10/12 lg:w-full">
              <div className="flex-1 flex flex-col items-start justify-start gap-8">
                <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-4">
                  {sports.map(sport => (
                    <Link to={`/listings?searchVal=${sport.name}`} className="bg-glass sport-types flex items-center rounded-2xl pr-4">
                      <img
                        className="max-h-full h-12 w-12 mx-2 object-cover z-[2]"
                        loading="lazy"
                        alt=""
                        src={`/${sport.icon}`}
                      />
                      <h5 className="title-gradient m-0">
                        {sport.name}
                      </h5>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="w-full flex flex-col items-start justify-start pr-[1.25rem] gap-8 mt-[90px]">
          <h2 className="title-gradient">
            Find by neighborhood
          </h2>
          <div className="w-full flex flex-row items-start justify-start">
            <div className="w-full flex lg:flex-col gap-6">
              {neighborhood.map(neighbor => (
                <div className="w-3/12 lg:w-full flex flex-col gap-4 group">
                  <img src={`/${neighbor.image}`} className="h-[250px] w-full rounded-2xl" alt="Bamba" />
                  <div className="name flex gap-4">
                    <h4 className="title-gradient m-0">{neighbor.name}</h4>
                    <img src="/ggchevrondowno-1.svg"
                      className="-rotate-90 hidden group-hover:block transform translate-x-[-20px] opacity-0 transition-all duration-300 ease-in-out group-hover:translate-x-0 group-hover:opacity-100"
                      alt="arrow" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default HeaderHome;
