// Main Component File: SelectSport.tsx
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast } from "react-toastify";
import AddIcon from '@mui/icons-material/Add';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';

// Components
import Navbar from "../components/Navbar";
import FooterHome from "../components/FooterHome";
import BookingHeader from "../components/BookingHeader";
import BookingSlider from "../components/BookingSlider";
import Button from "../components/Button";
import ActivitySelector from "../components/ActivitySelector";
import DateSelector from "../components/DateSelector";
import TimeSlotSelector from "../components/TimeSlotSelector";
import BookingSummary from "../components/BookingSummary";

// Services and Utilities
import { fetchLocation, fetchActivities, fetchAvailableTimes } from "../services/bookingService";
import {
    formatTimeIntervals,
    findEarliestAndLatestTimes,
    checkTimingContinuity,
    FormattedTimeSlot,
    timeToMinutes,
    convertTo24HourFormat,
    ensureTimeIs12HourFormat,
    normalizeTimingFormat
} from "../state/timeUtils";

// Types
import { ActivityListProps, TimeSlot } from "../types";

// Booking Activity interface
interface BookingActivity {
    id: string;
    activityId: string;
    date: dayjs.Dayjs | null;
    selectedTimings: Timing[];
    timeSlots: FormattedTimeSlot[]; // Changed from timings: string[]
    isLoading: boolean;
}

// Timing interface
interface Timing {
    startTime: string;
    endTime: string;
}

// Initialize dayjs plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Helper function to convert between date formats
const convertDateFormat = (dateStr: string): string => {
    // Handle "DD-MM-YYYY" format
    if (dateStr.includes("-")) {
        const parts = dateStr.split("-");
        if (parts.length === 3) {
            // Check if in DD-MM-YYYY format
            if (parts[0].length <= 2 && parts[1].length <= 2 && parts[2].length === 4) {
                return `${parts[2]}-${parts[1]}-${parts[0]}`; // Convert to YYYY-MM-DD for dayjs
            }
        }
    }

    // If it's already in the right format or we can't parse it, return as is
    return dateStr;
};

const SelectSport: FunctionComponent = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const today = dayjs();
    const locationId = searchParams.get("locationId") || "";

    // Location and activity data
    const [listing, setListing] = useState<{ name: string; address: string; contact: string, bookingWindow: number } | undefined>();
    const [activityList, setActivityList] = useState<ActivityListProps[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const bookingWindow = listing?.bookingWindow || 30;
    const maxDate = dayjs().add(bookingWindow, "day");

    // Ref to keep track of the next booking ID
    const nextBookingIdRef = useRef(1);

    // Multiple booking activities
    const [bookingActivities, setBookingActivities] = useState<BookingActivity[]>([
        {
            id: `booking-${nextBookingIdRef.current}`,
            activityId: searchParams.get("activityId") || "",
            date: null, // Changed to null so no auto-fetching happens
            selectedTimings: [],
            timeSlots: [],
            isLoading: false
        }
    ]);

    // Calculate total price for all booking activities
    const totalPrice = useMemo(() => {
        return bookingActivities.reduce((total, activity) => {
            const selectedActivity = activityList.find(
                item => item.id === Number(activity.activityId)
            );

            if (!selectedActivity) return total;

            const activityPrice = selectedActivity.price * activity.selectedTimings.length;
            return total + activityPrice;
        }, 0);
    }, [bookingActivities, activityList]);

    // Load initial data from localStorage if in edit mode
    useEffect(() => {
        const isEditMode = searchParams.get("edit") === "true";
        if (isEditMode) {
            try {
                // Check for editBookingData first (from Confirm page editing)
                const editData = localStorage.getItem("editBookingData");

                if (editData) {
                    const parsedEditData = JSON.parse(editData);
                    console.log("Loading edit data:", parsedEditData);

                    if (parsedEditData.bookings && parsedEditData.bookings.length > 0) {
                        // Convert the bookings to our expected format
                        const mappedBookings = parsedEditData.bookings.map((booking: any, index: any) => {
                            // Ensure selectedTimings are in 12-hour format for display in the selector
                            const normalizedTimings = booking.selectedTimings?.map((timing: any) => ({
                                startTime: ensureTimeIs12HourFormat(timing.startTime),
                                endTime: ensureTimeIs12HourFormat(timing.endTime)
                            })) || [];

                            // Use a numeric ID for each booking
                            const bookingId = index + 1;

                            // Update the next ID reference to be higher than any existing ID
                            nextBookingIdRef.current = Math.max(nextBookingIdRef.current, bookingId + 1);

                            return {
                                id: `booking-${bookingId}`,
                                activityId: booking.activityId || "",
                                date: booking.date ? dayjs(convertDateFormat(booking.date)) : today,
                                selectedTimings: normalizedTimings,
                                timeSlots: [],
                                isLoading: false
                            };
                        });

                        setBookingActivities(mappedBookings);

                        // Clear the edit data so it doesn't persist beyond this session
                        localStorage.removeItem("editBookingData");
                        return;
                    }
                }

                // Fall back to regular bookingData if editBookingData is not available
                const storedData = JSON.parse(localStorage.getItem("bookingData") || "{}");
                if (Object.keys(storedData).length > 0) {
                    if (storedData.bookings && storedData.bookings.length > 0) {
                        // New format with multiple bookings
                        const mappedBookings = storedData.bookings.map((booking: any, index: any) => {
                            // Ensure selectedTimings are in 12-hour format for display
                            const normalizedTimings = booking.selectedTimings?.map((timing: any) => ({
                                startTime: ensureTimeIs12HourFormat(timing.startTime),
                                endTime: ensureTimeIs12HourFormat(timing.endTime)
                            })) || [];

                            // Use a numeric ID for each booking
                            const bookingId = index + 1;

                            // Update the next ID reference to be higher than any existing ID
                            nextBookingIdRef.current = Math.max(nextBookingIdRef.current, bookingId + 1);

                            return {
                                id: `booking-${bookingId}`,
                                activityId: booking.activityId || "",
                                date: booking.date ? dayjs(convertDateFormat(booking.date)) : today,
                                selectedTimings: normalizedTimings,
                                timeSlots: [],
                                isLoading: false
                            };
                        });

                        setBookingActivities(mappedBookings);
                    } else {
                        // Legacy format with single booking
                        const normalizedTimings = storedData.selectedTimings?.map((timing: any) => ({
                            startTime: ensureTimeIs12HourFormat(timing.startTime),
                            endTime: ensureTimeIs12HourFormat(timing.endTime)
                        })) || [];

                        // Set the next booking ID to 2 since we're using ID 1 for the first booking
                        nextBookingIdRef.current = 2;

                        setBookingActivities([{
                            id: "booking-1",
                            activityId: storedData.activityId || "",
                            date: storedData.date ? dayjs(convertDateFormat(storedData.date)) : today,
                            selectedTimings: normalizedTimings,
                            timeSlots: [],
                            isLoading: false
                        }]);
                    }
                }
            } catch (error) {
                toast.error("Failed to load previous booking data");
                console.error("Error loading stored booking data:", error);
            }
        }
    }, [searchParams]);

    // Fetch location and activities data
    useEffect(() => {
        const loadInitialData = async () => {
            if (!locationId) return;

            setIsLoading(true);

            try {
                const [locationData, activitiesData] = await Promise.all([
                    fetchLocation(locationId),
                    fetchActivities(locationId)
                ]);

                setListing(locationData);
                setActivityList(activitiesData);
            } catch (error) {
                toast.error("Failed to load location data");
                console.error("Error fetching initial data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        loadInitialData();
    }, [locationId]);

    // Create a function to fetch time slots for a specific activity
    const fetchTimeSlotsForActivity = useCallback(async (activityId: string, date: any, bookingId: string) => {
        if (!date || !activityId || activityId === "0") {
            return;
        }

        setBookingActivities(prev => prev.map(activity => {
            if (activity.id === bookingId) {
                return { ...activity, isLoading: true };
            }
            return activity;
        }));

        try {
            const formattedDate = date.format("YYYY-MM-DD");
            console.log(`Fetching timeslots for activity ${bookingId}, date:`, formattedDate);

            const availableTimes = await fetchAvailableTimes(locationId, activityId, formattedDate);
            const formattedTimeSlots = formatTimeIntervals(availableTimes);

            setBookingActivities(prev => prev.map(activity => {
                if (activity.id === bookingId) {
                    return {
                        ...activity,
                        timeSlots: formattedTimeSlots,
                        isLoading: false
                    };
                }
                return activity;
            }));
        } catch (error) {
            toast.error(`Failed to load time slots for activity ${bookingId}`);
            setBookingActivities(prev => prev.map(activity => {
                if (activity.id === bookingId) {
                    return {
                        ...activity,
                        timeSlots: [],
                        isLoading: false
                    };
                }
                return activity;
            }));
        }
    }, [locationId]);

    // This effect will run only for initial load and edit mode
    useEffect(() => {
        // For each activity with both date and activityId set, fetch time slots
        bookingActivities.forEach(activity => {
            if (activity.date && activity.activityId && activity.activityId !== "0" && activity.timeSlots.length === 0 && !activity.isLoading) {
                fetchTimeSlotsForActivity(activity.activityId, activity.date, activity.id);
            }
        });
    }, []);

    // Event handlers
    const handleActivityChange = useCallback((activityId: string, bookingId: string) => {
        setBookingActivities(prev => {
            const updatedActivities = prev.map(activity => {
                if (activity.id === bookingId) {
                    return {
                        ...activity,
                        activityId,
                        // Reset time selections when activity changes
                        selectedTimings: [],
                        timeSlots: [] // Reset time slots when activity changes
                    };
                }
                return activity;
            });

            // Find the updated activity to check if it has a date
            const updatedActivity = updatedActivities.find(a => a.id === bookingId);

            // If activity has a valid ID and already has a date selected, fetch time slots
            if (updatedActivity && activityId && activityId !== "0" && updatedActivity.date) {
                // Use setTimeout to ensure state is updated before fetching
                setTimeout(() => {
                    fetchTimeSlotsForActivity(activityId, updatedActivity.date, bookingId);
                }, 0);
            }

            return updatedActivities;
        });
    }, [fetchTimeSlotsForActivity]);

    const handleDateChange = useCallback((date: dayjs.Dayjs | null, bookingId: string) => {
        if (!date) return;

        console.log(`Date changed for booking ${bookingId}:`, date.format("YYYY-MM-DD"));

        // First update the state with the new date
        setBookingActivities(prev => {
            const updatedActivities = prev.map(activity => {
                if (activity.id === bookingId) {
                    return {
                        ...activity,
                        date,
                        // Reset time selections when date changes
                        selectedTimings: [],
                        timeSlots: [] // Reset time slots when date changes
                    };
                }
                return activity;
            });

            // Find the updated activity to use for fetching
            const updatedActivity = updatedActivities.find(a => a.id === bookingId);

            // If activity has a valid ID, trigger time slot fetching
            if (updatedActivity && updatedActivity.activityId && updatedActivity.activityId !== "0") {
                // Use setTimeout to ensure state is updated before fetching
                setTimeout(() => {
                    fetchTimeSlotsForActivity(updatedActivity.activityId, date, bookingId);
                }, 0);
            }

            return updatedActivities;
        });
    }, [fetchTimeSlotsForActivity]);

    const getOccupiedTimeSlots = useCallback((currentBookingId: string, activityId: string, date: dayjs.Dayjs | null) => {
        if (!date || !activityId) return [];

        // Get the court ID for the current activity
        const currentActivity = activityList.find(item => item.id === Number(activityId));
        if (!currentActivity) return [];

        return bookingActivities
            .filter(activity => {
                if (activity.id === currentBookingId) return false;
                if (!activity.date) return false;
                // Fix the date comparison
                if (activity.date.format("YYYY-MM-DD") !== date.format("YYYY-MM-DD")) return false;

                // Find the activity details to get the courtId
                const activityDetails = activityList.find(item => item.id === Number(activity.activityId));
                // Include if it's the same court
                return activityDetails?.courtId === currentActivity.courtId;
            })
            .flatMap(activity =>
                activity.selectedTimings.map(timing => `${timing.startTime} - ${timing.endTime}`)
            );
    }, [bookingActivities, activityList]);

    const handleTimeSelectionChange = useCallback((selectedTimes: string[], bookingId: string) => {
        // Convert string time slots to timing objects
        const newTimings = selectedTimes.map(timeString => {
            const [startTime, endTime] = timeString.split(" - ");
            return { startTime, endTime };
        });

        // Sort by start time using the timeToMinutes utility for proper time comparison
        newTimings.sort((a, b) => {
            const startTimeA = timeToMinutes(a.startTime);
            const startTimeB = timeToMinutes(b.startTime);
            return startTimeA - startTimeB;
        });

        // Check if times are continuous
        const isContinuous = checkTimingContinuity(newTimings);

        if (!isContinuous) {
            toast.error("Please select continuous time slots");
            return;
        }

        setBookingActivities(prev => prev.map(activity => {
            if (activity.id === bookingId) {
                return { ...activity, selectedTimings: newTimings };
            }
            return activity;
        }));
    }, []);

    const handleAddBookingActivity = useCallback(() => {
        // This is the key line - we need to increment the counter BEFORE adding the new booking
        nextBookingIdRef.current += 1;
        
        // Use the ref to generate a unique ID and then increment it
        const newId = `booking-${nextBookingIdRef.current}`;

        console.log(`Adding new booking with ID: ${newId}, next ID will be: ${nextBookingIdRef.current}`);

        setBookingActivities(prev => [
            ...prev,
            {
                id: newId,
                activityId: "",
                date: null,
                selectedTimings: [],
                timeSlots: [],
                isLoading: false
            }
        ]);
    }, []);

    const handleRemoveBookingActivity = useCallback((bookingId: string) => {
        setBookingActivities(prev => {
            // Don't remove if there's only one booking activity
            if (prev.length <= 1) return prev;

            return prev.filter(activity => activity.id !== bookingId);
        });
    }, []);

    const handleConfirmBooking = useCallback((e?: React.MouseEvent) => {
        // Prevent default form submission behavior if an event is passed
        if (e) {
            e.preventDefault();
        }

        // Check if any booking has no selected timings
        const invalidBookings = bookingActivities.some(
            activity => activity.selectedTimings.length === 0
        );

        if (invalidBookings) {
            toast.error("Please select valid time slots for all activities");
            return; // Early return to prevent navigation
        }

        try {
            // Prepare booking data for localStorage
            const bookingData = bookingActivities.map(activity => {
                // Validate that we have valid activity data
                if (!activity.date) {
                    throw new Error(`Missing date for activity ${activity.id}`);
                }

                if (!activity.activityId) {
                    throw new Error(`Missing activityId for activity ${activity.id}`);
                }

                if (activity.selectedTimings.length === 0) {
                    throw new Error(`No time slots selected for activity ${activity.id}`);
                }

                // Always return 24-hour format times for API consistency
                const { earliest, latest } = findEarliestAndLatestTimes(activity.selectedTimings, true);

                if (!earliest || !latest) {
                    throw new Error(`Could not determine time range for activity ${activity.id}`);
                }

                const selectedActivity = activityList.find(
                    item => item.id === Number(activity.activityId)
                );

                if (!selectedActivity) {
                    throw new Error(`Selected activity not found: ${activity.activityId}`);
                }

                return {
                    id: activity.id,
                    earliest, // In 24-hour format
                    latest,   // In 24-hour format
                    date: activity.date.format("DD-MM-YYYY"),
                    sportTypeName: selectedActivity.typeName || "",
                    courtName: selectedActivity.name || "",
                    // Store selectedTimings in 24-hour format for API consistency
                    selectedTimings: activity.selectedTimings.map(timing => ({
                        startTime: convertTo24HourFormat(timing.startTime),
                        endTime: convertTo24HourFormat(timing.endTime)
                    })),
                    activityPrice: selectedActivity.price * activity.selectedTimings.length,
                    activityId: activity.activityId,
                    courtId: selectedActivity.courtId
                };
            });

            const combinedBookingData = {
                bookings: bookingData,
                totalPrice,
                locationId
            };

            // Log what we're saving to localStorage for debugging
            console.log("Saving booking data to localStorage:", combinedBookingData);

            localStorage.setItem("bookingData", JSON.stringify(combinedBookingData));
            navigate(`/confirm-booking?locationId=${locationId}`);
        } catch (error) {
            console.error("Error preparing booking data:", error);
            toast.error("Error preparing booking data: " + (error instanceof Error ? error.message : "Unknown error"));
        }
    }, [bookingActivities, activityList, totalPrice, locationId, navigate]);

    return (
        <div className="w-full relative bg-dark-blue overflow-hidden flex flex-col items-start justify-start pt-[3.25rem] px-[0rem] pb-[0rem] box-border gap-[1.75rem_0rem] tracking-[normal]">
            <Navbar />
            <div className="w-[71rem] lg:w-full mx-auto">
                <div className="w-full lg:w-full">
                    {/* Location header */}
                    <BookingHeader
                        name={listing?.name || ''}
                        address={listing?.address || ''}
                        contact={listing?.contact || ''}
                    />

                    {/* Location slider */}
                    <BookingSlider id={locationId} />

                    <form className="flex flex-col gap-14 lg:px-5">
                        <div>
                            <h4 className="title-gradient mn-0">Add a Booking</h4>
                            <hr className="w-full border border-[#7D7D7D] m-0" />
                        </div>
                        <div className="flex flex-col gap-4">
                            <p className="label2 title-gradient">
                                Select activity
                            </p>
                            {/* Booking activities */}
                            {bookingActivities.map((bookingActivity, index) => (
                                <div key={bookingActivity.id} className="booking-activity lg:pt-8">
                                    <div className="grid grid-cols-4 lg:grid-cols-1 gap-4 items-center lg:items-start justify-between">
                                        {/* Activity selection */}
                                        <ActivitySelector
                                            activities={activityList}
                                            selectedId={bookingActivity.activityId || "0"}
                                            onChange={(id) => handleActivityChange(id, bookingActivity.id)}
                                            price={
                                                bookingActivity.activityId
                                                    ? (activityList.find(a => a.id === Number(bookingActivity.activityId))?.price || 0)
                                                    : 0
                                            }
                                            activityType={
                                                activityList.find(a => a.id === Number(bookingActivity.activityId))?.typeName
                                            }
                                            isLoading={isLoading}
                                        />
                                        <div className="grid grid-cols-2 lg:grid-cols-1 items-center gap-4 col-span-2">
                                            {/* Date selection */}
                                            <DateSelector
                                                date={bookingActivity.date}
                                                onChange={(date) => handleDateChange(date, bookingActivity.id)}
                                                maxDate={maxDate}
                                                isLoading={isLoading}
                                                disabled={!bookingActivity.activityId || bookingActivity.activityId === "0"}
                                            />
                                            <div className="flex lg:flex-col gap-4 items-center lg:items-start">
                                                {/* Time selection */}
                                                <TimeSlotSelector
                                                    timeSlots={bookingActivity.timeSlots}
                                                    selectedTimings={bookingActivity.selectedTimings}
                                                    onChange={(selectedTimes: any) => handleTimeSelectionChange(selectedTimes, bookingActivity.id)}
                                                    error={null}
                                                    isLoading={bookingActivity.isLoading}
                                                    occupiedTimeSlots={getOccupiedTimeSlots(
                                                        bookingActivity.id,
                                                        bookingActivity.activityId,
                                                        bookingActivity.date
                                                    )}
                                                />

                                                {/* Add/Remove button */}
                                                <div className="flex items-center">
                                                    {bookingActivities.length > 1 ? (
                                                        <button
                                                            type="button"
                                                            className="flex body items-center lg:justify-start justify-center rounded-full p-0 text-pink bg-transparent"
                                                            onClick={() => handleRemoveBookingActivity(bookingActivity.id)}
                                                            aria-label="Remove activity"
                                                        >
                                                            <DoDisturbOnIcon className="pr-2" />
                                                            <span className="hidden lg:block">
                                                                Remove
                                                            </span>
                                                        </button>
                                                    ) : ("")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <button
                                type="button"
                                className="flex body mt-4 w-fit items-center justify-center p-2 rounded-2xl interactive-gradient-bg"
                                onClick={handleAddBookingActivity}
                                aria-label="Add another activity"
                            >
                                <AddIcon className="text-lg" />
                                Add Activity
                            </button>
                        </div>

                        {/* Booking summary */}
                        <BookingSummary
                            totalPrice={totalPrice}
                            bookings={bookingActivities.map(activity => ({
                                activityName: activityList.find(a => a.id === Number(activity.activityId))?.name || '',
                                date: activity.date?.format("DD MMM YYYY") || '',
                                times: activity.selectedTimings.map(t => `${t.startTime} - ${t.endTime}`).join(', '),
                                price: (activityList.find(a => a.id === Number(activity.activityId))?.price || 0) *
                                    activity.selectedTimings.length
                            }))}
                        />

                        {/* Confirm booking section */}
                        <div className="confirm flex flex-col gap-4 w-fit lg:w-full">
                            <button
                                disabled={totalPrice === 0 || isLoading}
                                onClick={(e: any) => handleConfirmBooking(e)}
                                type="button"
                                className={`mt-4 btnPrimary ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                            >
                                <div className="flex-1 body text-navy-blue">
                                    {isLoading ? "Loading..." : "Confirm Booking"}
                                </div>
                            </button>
                            <div className="w-[24rem] text-white">
                                The next step is to choose your payment method.
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <FooterHome />
        </div>
    );
};

export default SelectSport;