import React, { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom"; // Added useLocation
import AuthRequest from "../services/authRequest";
import { toast } from "react-toastify";
import UserRequest from "../services/userRequest";
import Button from "./Button"; // Assuming Button component exists
import { API_URL, SANDBOX } from "../config"; // Assuming config exists
import AuthStatus from "./AuthStatus"; // Assuming AuthStatus component exists
// Ensure all necessary time utility functions are imported
import { convertTo12HourFormat, convertTo24HourFormat, ensureTimeIs12HourFormat } from "../state/timeUtils"; // Assuming timeUtils exist

// Helper function to format API date (Handles YYYY-MM-DDTHH:mm:ss format)
// Converts API date format (like YYYY-MM-DDTHH:...) to DD-MM-YYYY for internal state
const formatApiDate = (apiDate: string): string => {
  if (!apiDate) return "";
  try {
    if (apiDate.includes("T")) {
      const dateOnly = apiDate.split("T")[0];
      const [year, month, day] = dateOnly.split("-");
      return `${day}-${month}-${year}`; // Convert to DD-MM-YYYY
    }
    // If it's already YYYY-MM-DD (no T)
    if (apiDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
      const [year, month, day] = apiDate.split("-");
      return `${day}-${month}-${year}`; // Convert to DD-MM-YYYY
    }
    // Assume it might already be DD-MM-YYYY, return as is
    if (apiDate.match(/^\d{2}-\d{2}-\d{4}$/)) {
      return apiDate;
    }
    console.warn("Unrecognized API date format, returning as is:", apiDate);
    return apiDate; // Return original if format is unexpected
  } catch (error) {
    console.error("Error formatting API date:", error);
    return apiDate; // Return original on error
  }
};


// Updated interface to match the new structure
interface BookingItem {
  id: string;
  earliest: string; // Should be 12hr format in state for display
  latest: string;   // Should be 12hr format in state for display
  date: string;     // Format: DD-MM-YYYY in state
  sportTypeName: string;
  courtName: string;
  courtId: number;
  selectedTimings: { startTime: string; endTime: string }[]; // Should be 12hr format in state
  activityPrice: number;
  activityId: string;
}

interface BookingData {
  bookings: BookingItem[];
  totalPrice: number;
  locationId: string;
}

// Interface to match the payment link API response format
interface PaymentLinkResponse {
  earliest: string; // API might send 24hr or 12hr - needs normalization
  latest: string;   // API might send 24hr or 12hr - needs normalization
  selectedDates: string[]; // API date format? Assume YYYY-MM-DD or similar
  sportTypeName: string;
  selectedTimings: { startTime: string; endTime: string }[]; // API time format? Assume 24hr/12hr
  courtName: string;
  totalPrice: number;
  activityId: number;
  locationId: number;
  repeat: boolean;
  date: string; // API date format? Assume YYYY-MM-DDTHH:mm:ss or similar
  isExpired: boolean;
  courtId: number;
}

interface PayHereModel {
  merchantId: string;
  hash: string;
  orderId: string;
  orderName: string;
  currency: string;
  amount: string;
}

declare global {
  interface Window {
    payhere: any;
  }
}

interface BookingLocationProps {
  name: string;
  address: string;
}

const ConfirmBookingComp: FunctionComponent<BookingLocationProps> = ({
  name,
  address,
}) => {
  const [searchParams] = useSearchParams();
  const location = useLocation(); // Use useLocation hook
  const [locationId] = useState(searchParams.get("locationId"));
  const [bookingData, setBookingData] = useState<BookingData | null>(null);
  const [paymentId, setPaymentId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPayHereLoaded, setIsPayHereLoaded] = useState(false);
  const [handlingFee, setHandlingFee] = useState<number>(0);
  const [expired, setExpired] = useState(false); // Keep this state if needed
  const navigate = useNavigate();

  // --- PayHere Script Loading ---
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.payhere.lk/lib/payhere.js";
    script.async = true;

    script.onload = () => {
      if (window.payhere) {
        setIsPayHereLoaded(true);
        console.log("PayHere script loaded successfully.");

        // Define PayHere callbacks
        window.payhere.onCompleted = (orderId: any) => {
          console.log("Payment completed for order:", orderId);
          navigate(`/booking-final?id=${orderId}`); // Navigate to final booking page
        };

        window.payhere.onDismissed = () => {
          console.log("Payment dismissed");
          toast.info("Payment process was cancelled."); // Inform user
        };

        window.payhere.onError = (error: any) => {
          toast.error("Payment failed. Please try again."); // User-friendly error
          console.error("PayHere Error:", error);
        };
      } else {
        console.error(
          "PayHere script loaded, but payhere object is not available."
        );
        toast.error("Payment gateway failed to load. Please refresh.");
      }
    };

    script.onerror = () => {
      console.error("Failed to load the PayHere script.");
      toast.error("Could not load payment gateway. Check connection?");
    };

    document.head.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      console.log("Cleaning up PayHere script and callbacks.");
      const existingScript = document.querySelector(`script[src="${script.src}"]`);
      if (existingScript && document.head.contains(existingScript)) {
        document.head.removeChild(existingScript);
      }
      if (window.payhere) {
        window.payhere.onCompleted = null;
        window.payhere.onDismissed = null;
        window.payhere.onError = null;
      }
    };
  }, [navigate]); // Add navigate to dependency array

  // --- Time Normalization for Display (12hr format) ---
  const normalizeBookingData = (data: BookingData): BookingData => {
    // Basic validation of input structure
    if (!data || !data.bookings || !Array.isArray(data.bookings)) {
      console.error("Invalid booking data structure received for normalization:", data);
      // Return a default structure or the original data to avoid crashing
      return data || { bookings: [], totalPrice: 0, locationId: '' };
    }

    try {
      const normalizedBookings = data.bookings.map(booking => {
        if (!booking) {
          console.warn("Skipping invalid booking entry during normalization:", booking);
          return null; // Skip null/undefined entries safely
        }
        // Ensure all parts of the booking object exist before processing
        const earliestTime = booking.earliest || "";
        const latestTime = booking.latest || "";
        const bookingDate = booking.date || "";
        const timings = booking.selectedTimings || [];

        return {
          ...booking,
          // Ensure times are consistently 12hr format for display
          earliest: ensureTimeIs12HourFormat(earliestTime), // Handles empty strings
          latest: ensureTimeIs12HourFormat(latestTime),     // Handles empty strings
          // Ensure selected timings are also 12hr format
          selectedTimings: Array.isArray(timings)
            ? timings.map(timing => ({
              startTime: timing?.startTime ? ensureTimeIs12HourFormat(timing.startTime) : "N/A",
              endTime: timing?.endTime ? ensureTimeIs12HourFormat(timing.endTime) : "N/A"
            }))
            : [],
          // Ensure date is in DD-MM-YYYY format for state consistency
          date: formatApiDate(bookingDate), // Use formatApiDate
        };
      }).filter(booking => booking !== null) as BookingItem[]; // Filter out any nulls

      return {
        ...data,
        bookings: normalizedBookings,
        // Ensure totalPrice is a number
        totalPrice: typeof data.totalPrice === 'number' ? data.totalPrice : 0,
      };
    } catch (error) {
      console.error("Error normalizing booking data:", error);
      toast.error("Error processing booking details.");
      // Return original data or a safe default on error
      return data;
    }
  };


  // --- Fetch Booking Details (localStorage or Payment Link) ---
  useEffect(() => {
    const currentSearchParams = new URLSearchParams(location.search);
    const paymentLinkParam = currentSearchParams.get("paymentLink");

    if (paymentLinkParam) {
      console.log("Found paymentLink parameter:", paymentLinkParam);
      setPaymentId(paymentLinkParam);
      getPaymentDetails(paymentLinkParam);
    } else {
      console.log("No paymentLink parameter found, checking localStorage.");
      getBookingDetailsFromStorage();
    }
    
  }, [location.search]); // Depend on location.search

  // --- Load from localStorage ---
  const getBookingDetailsFromStorage = () => {
    const storedData = localStorage.getItem("bookingData");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        console.log("Raw data from localStorage:", parsedData);

        // Validate parsed data structure before normalization
        if (parsedData && parsedData.bookings && parsedData.totalPrice !== undefined) {
          // Normalize data for display (convert times to 12hr, format date)
          const normalizedData = normalizeBookingData(parsedData);
          setBookingData(normalizedData);
          console.log("Normalized data set from localStorage:", normalizedData);

          // Calculate handling fee (ensure totalPrice is a number)
          const fee = (typeof normalizedData.totalPrice === 'number' ? normalizedData.totalPrice : 0) * 0.05;
          setHandlingFee(fee);
        } else {
          console.error("Parsed data from localStorage has invalid structure:", parsedData);
          toast.error("Stored booking details are corrupted.");
          localStorage.removeItem("bookingData"); // Clear corrupted data
        }

      } catch (error) {
        console.error("Error parsing booking data from localStorage:", error);
        toast.error("Failed to load booking details from storage.");
        localStorage.removeItem("bookingData"); // Clear potentially corrupted data
      }
    } else {
      console.log("No booking data found in localStorage.");
      // Don't navigate away automatically, let the UI show "No booking data found."
    }
  };


  // --- Load from Payment Link API ---
  const getPaymentDetails = async (paymentLinkId: string) => {
    setIsLoading(true);
    try {
      const response = await AuthRequest.get(
        `/Booking/customer/paymentLink?paymentLink=${paymentLinkId}`
      );

      // Validate API response structure
      if (!response.data || !response.data.data) {
        console.error("Invalid API response structure for payment link:", response);
        throw new Error("Received invalid data from payment link API.");
      }
      const apiData: PaymentLinkResponse = response.data.data;
      console.log("Payment link API response:", apiData);

      // Check if the link is expired
      if (apiData.isExpired) {
        setExpired(true);
        toast.error("This payment link has expired.");
        navigate('/timeout'); // Redirect to a timeout/expired page
        return;
      }

      // --- Convert API Response to Standard BookingData ---
      // Determine the primary date source, format it to DD-MM-YYYY
      const bookingDate = formatApiDate(apiData.selectedDates?.[0] || apiData.date || "");
      if (!bookingDate) {
        console.error("Could not determine a valid date from payment link API data:", apiData);
        throw new Error("Missing date information in payment link details.");
      }

      const convertedData: BookingData = {
        bookings: [{
          id: `payment-${paymentLinkId}-${apiData.activityId || 'na'}`, // More specific ID
          // Normalize times from API to 12hr format for display consistency
          earliest: ensureTimeIs12HourFormat(apiData.earliest || ""),
          latest: ensureTimeIs12HourFormat(apiData.latest || ""),
          date: bookingDate, // Use the formatted date (DD-MM-YYYY)
          sportTypeName: apiData.sportTypeName || "N/A",
          courtName: apiData.courtName || "N/A",
          courtId: apiData.courtId || 0, // Provide default
          selectedTimings: Array.isArray(apiData.selectedTimings)
            ? apiData.selectedTimings.map((timing: any) => ({
              startTime: ensureTimeIs12HourFormat(timing?.startTime || ""),
              endTime: ensureTimeIs12HourFormat(timing?.endTime || "")
            }))
            : [],
          activityPrice: typeof apiData.totalPrice === 'number' ? apiData.totalPrice : 0,
          activityId: apiData.activityId?.toString() || "N/A"
        }],
        totalPrice: typeof apiData.totalPrice === 'number' ? apiData.totalPrice : 0,
        locationId: apiData.locationId?.toString() || "N/A"
      };

      setBookingData(convertedData); // Set the converted data
      setHandlingFee(convertedData.totalPrice * 0.05); // Calculate fee
      console.log("Converted payment link data set:", convertedData);

    } catch (error: any) {
      console.error("Error fetching payment details:", error);
      if (error.response?.status === 404) {
        toast.error("Payment link not found or invalid.");
        navigate('/locations'); // Redirect if link is invalid
      } else if (error.message.includes("Invalid data") || error.message.includes("Missing date")) {
        toast.error("Received incomplete payment details. Please try again or contact support.");
      }
      else {
        toast.error("Failed to load payment details. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // --- Handle PayHere Payment Initiation ---
  const handlePayment = (payhereDetails: PayHereModel | null) => {
    // Ensure PayHere is loaded and details are present
    if (!isPayHereLoaded || !window.payhere) {
      toast.error("Payment gateway is not ready. Please wait or refresh.");
      console.error("Attempted payment initiation before PayHere was ready.");
      setIsLoading(false);
      return;
    }
    if (!payhereDetails || !payhereDetails.merchantId || !payhereDetails.hash || !payhereDetails.orderId || !payhereDetails.amount) {
      toast.error("Payment details are incomplete. Cannot proceed.");
      console.error("handlePayment called with incomplete payhereDetails:", payhereDetails);
      setIsLoading(false);
      return;
    }

    // Ensure amount is a string with two decimal places
    const formattedAmount = parseFloat(payhereDetails.amount).toFixed(2);

    const payment = {
      sandbox: SANDBOX, // Use SANDBOX flag from config
      merchant_id: payhereDetails.merchantId,
      return_url: `${window.location.origin}/booking-final?id=${payhereDetails.orderId}`, // Use origin for dynamic URL
      cancel_url: window.location.href, // Current page URL for cancellation
      notify_url: `${API_URL}/booking/customer/verify-booking`, // Your backend notification URL
      order_id: payhereDetails.orderId,
      items: payhereDetails.orderName || `Booking at ${name || 'Venue'}`, // Fallback item name
      amount: formattedAmount,
      currency: payhereDetails.currency || "LKR", // Default currency
      hash: payhereDetails.hash, // Hash generated by your backend
      // Retrieve user details safely with fallbacks
      first_name: localStorage.getItem("firstName") || "",
      last_name: localStorage.getItem("lastName") || "",
      email: localStorage.getItem("email") || "",
      phone: localStorage.getItem("contact") || "",
      address: localStorage.getItem("address") || "",
      city: localStorage.getItem("city") || "",
      country: "Sri Lanka",
    };

    console.log("Starting PayHere payment with details:", payment);
    try {
      window.payhere.startPayment(payment);
      // isLoading state remains true until PayHere callbacks (onCompleted, onDismissed, onError) handle it or navigation occurs.
    } catch (error) {
      console.error("Error initiating PayHere payment:", error);
      toast.error("Could not start the payment process. Please try again.");
      setIsLoading(false); // Reset loading state if startPayment fails immediately
    }
  };


  // --- Create Booking via API (and then initiate payment) ---
  const createCustomerBooking = async () => {
    if (!bookingData || !bookingData.bookings || !bookingData.bookings.length) {
      toast.error("No booking data available to proceed.");
      return;
    }

    setIsLoading(true);

    try {
      // --- Prepare Booking Requests for API (Convert times back to 24hr) ---
      const bookingRequests = bookingData.bookings.map(booking => {
        // Validate essential booking details
        if (!booking.date || !booking.earliest || !booking.latest || !booking.activityId || !booking.courtId || !bookingData.locationId) {
          console.error("Incomplete booking item details:", booking);
          throw new Error("One or more booking details are missing.");
        }
        // Convert date from DD-MM-YYYY (state format) back to YYYY-MM-DD for API
        const dateParts = booking.date.split("-");
        if (dateParts.length !== 3) throw new Error(`Invalid date format in state: ${booking.date}`);
        const [day, month, year] = dateParts;
        const apiDate = `${year}-${month}-${day}`;

        // *** FIX: Convert 12hr times from state back to 24hr format for API ***
        const startTime24hr = convertTo24HourFormat(booking.earliest);
        const endTime24hr = convertTo24HourFormat(booking.latest);

        // Validate conversion results (ensure they are not empty or null)
        if (!startTime24hr) throw new Error(`Invalid start time format: ${booking.earliest}`);
        if (!endTime24hr) throw new Error(`Invalid end time format: ${booking.latest}`);


        // Construct ISO-like strings using the 24hr times (adjust format if API needs Z or offset)
        const apiStartTime = `${apiDate}T${startTime24hr}:00`; // Format: YYYY-MM-DDTHH:mm:ss
        const apiEndTime = `${apiDate}T${endTime24hr}:00`;     // Format: YYYY-MM-DDTHH:mm:ss
        const apiDateOnly = `${apiDate}T00:00:00`;             // Format: YYYY-MM-DDTHH:mm:ss (start of day)

        return {
          locationId: parseInt(bookingData.locationId), // Ensure locationId is parsed
          date: apiDateOnly,       // Send date part as required by API
          startTime: apiStartTime, // Send full dateTime in 24hr format
          endTime: apiEndTime,     // Send full dateTime in 24hr format
          activityId: parseInt(booking.activityId), // Ensure activityId is parsed
          courtId: booking.courtId, // Already a number
        };
      });

      console.log("API Booking Request Payload:", bookingRequests);

      // --- Check Availability ---
      console.log("Checking booking availability...");
      const availabilityResponse = await UserRequest.post("/v1/Booking/customer/booking-available", {
        customerActivitiesList: bookingRequests
      });

      console.log("Availability Response:", availabilityResponse.data);
      // Ensure success field exists and is true
      if (!availabilityResponse.data?.success) {
        const unavailableSlots = availabilityResponse.data?.message || "Some selected time slots are no longer available.";
        toast.error(unavailableSlots);
        setIsLoading(false);
        // Consider navigating back or prompting user to re-select
        // navigate(`/select-sport?locationId=${locationId}`);
        return;
      }

      // --- Add Booking ---
      console.log("Availability confirmed. Adding booking...");
      const addBookingResponse = await UserRequest.post(
        "/v1/Booking/customer/add-booking",
        {
          customerActivitiesList: bookingRequests
        }
      );

      console.log("Add Booking Response:", addBookingResponse.data);
      // Validate structure of payment details received
      if (!addBookingResponse.data?.data || !addBookingResponse.data.data.hash) {
        console.error("Invalid payment details received from add-booking response:", addBookingResponse.data);
        throw new Error("Failed to get valid payment details after booking confirmation.");
      }
      const paymentDetails: PayHereModel = addBookingResponse.data.data;

      // --- Initiate Payment ---
      console.log("Booking added successfully. Initiating payment...");
      handlePayment(paymentDetails);
      // Clear local storage *after* successfully initiating payment process
      localStorage.removeItem("bookingData");


    } catch (error: any) {
      console.error("Error during booking creation process:", error);
      let errorMessage = "An error occurred while creating your booking.";
      if (error.response) {
        errorMessage = error.response.data?.message || `Booking failed: ${error.response.statusText || error.response.status}`;
        console.error("API Error Response:", error.response.data || error.response.status);
      } else if (error.message.includes("Invalid") || error.message.includes("Missing")) {
        // Catch specific validation errors thrown above
        errorMessage = error.message;
      }
      toast.error(errorMessage);
      setIsLoading(false); // Ensure loading state is reset on any error during creation
    }
    // isLoading state is managed by handlePayment or reset in catch block
  };

  // --- Get Payment Details for Existing Link (and initiate payment) ---
  const getPaymentDetailsWithLink = async (paymentLinkId: string) => {
    setIsLoading(true);
    try {
      console.log("Fetching payment details for link:", paymentLinkId);
      // This endpoint likely returns the PayHereModel directly
      const response = await UserRequest.post(
        // Ensure the endpoint is correct, might be GET or POST depending on API design
        `/Booking/customer/payment-details?paymentLink=${paymentLinkId}`
      );

      // Validate response structure
      if (!response.data?.data || !response.data.data.hash) {
        console.error("Invalid payment details received for link:", response.data);
        throw new Error("Failed to retrieve valid payment details for this link.");
      }
      const paymentDetails: PayHereModel = response.data.data;
      console.log("Payment Details Response:", paymentDetails);

      console.log("Payment details retrieved. Initiating payment...");
      handlePayment(paymentDetails);

    } catch (error: any) {
      console.error("Error getting payment details with link:", error);
      let errorMessage = "Failed to retrieve payment details.";
      if (error.response) {
        errorMessage = error.response.data?.message || `Error: ${error.response.statusText || error.response.status}`;
        console.error("API Error Response:", error.response.data || error.response.status);
      } else if (error.message.includes("Failed to retrieve")) {
        errorMessage = error.message;
      }
      toast.error(errorMessage);
      setIsLoading(false); // Reset loading state on error
    }
    // isLoading state is managed by handlePayment or reset in catch block
  };

  // --- Pay Now Button Handler ---
  const payNow = async () => {
    if (isLoading || !isPayHereLoaded) {
      console.warn("PayNow clicked while busy or PayHere not loaded.");
      if (!isPayHereLoaded) toast.info("Payment gateway is loading, please wait...");
      return; // Prevent double-clicks or premature clicks
    }

    if (!paymentId && bookingData) {
      // Scenario 1: Standard booking flow (data from localStorage)
      console.log("Initiating standard booking creation...");
      await createCustomerBooking();
    } else if (paymentId && bookingData) {
      // Scenario 2: Paying via a payment link
      console.log("Initiating payment via existing link...");
      await getPaymentDetailsWithLink(paymentId);
    } else {
      // Scenario 3: Error state (no data or link)
      console.error("PayNow clicked but bookingData or paymentId is missing.");
      toast.error("Cannot proceed with payment. Booking details are missing or invalid.");
    }
  };

  // --- Login Handler ---
  const completeLogin = () => {
    // Get the current URL search parameters
    const currentUrl = new URL(window.location.href);
    const searchParamsString = currentUrl.search; // e.g., "?locationId=123" or "?paymentLink=xyz"

    // Construct the resume path including the query parameters
    const resumePath = `confirm-booking${searchParamsString}`;
    console.log("Setting resume path:", resumePath);

    localStorage.setItem("resumePath", resumePath);
    navigate("/login"); // Redirect to login page
  };

  // --- Date Formatting for Display (Original Logic) ---
  // This function takes DD-MM-YYYY (from state) and formats to DD MMM YYYY for display
  function formatDate(dateStr: string): string {
    // Input expected: DD-MM-YYYY (from normalization)
    if (!dateStr || !dateStr.includes("-")) {
      console.warn("Invalid date format for display:", dateStr);
      return dateStr || "Invalid Date"; // Return original or placeholder
    }

    try {
      const parts = dateStr.split("-");
      if (parts.length !== 3) {
        console.error("Invalid date parts:", parts);
        return "Invalid Date";
      }
      const [day, month, year] = parts;
      // Note: Month is 0-indexed in JS Date constructor
      const dateObj = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

      // Check if the date object is valid
      if (isNaN(dateObj.getTime())) {
        console.error("Could not parse date string:", dateStr);
        return "Invalid Date";
      }

      // Format to "DD MMM YYYY" (e.g., "01 Apr 2025") - Original Format
      return dateObj.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }).replace(/ /g, " "); // Keep original replace, though likely redundant
    } catch (error) {
      console.error("Error formatting date:", dateStr, error);
      return dateStr; // Fallback to original string on error
    }
  }


  // --- Render Logic (Original UI Structure) ---
  return (
    <div className="w-full flex flex-col items-start justify-start gap-[4rem_0rem] text-white">
      <div className="self-stretch flex flex-col items-end justify-start gap-[1.75rem_0rem] max-w-full">
        {/* Loading State */}
        {isLoading && !bookingData && (
          <div className="flex flex-col items-center justify-center w-full py-12">
            <p>Loading booking details...</p>
            {/* You can add a spinner here if needed */}
          </div>
        )}

        {/* No Booking Data State */}
        {!isLoading && !bookingData && (
          <div className="flex flex-col items-center justify-center w-full py-12">
            <div className="text-center">
              <p className="text-xl mb-4">No booking data found.</p>
              <Button
                btnText="Go back home"
                click={() => navigate("/")}
              />
            </div>
          </div>
        )}

        {/* Booking Data Loaded State */}
        {bookingData ? (
          <div className="booking-details w-full">
            {/* Multiple bookings display */}
            <div className="flex flex-row lg:flex-col gap-6 w-full pb-12">
              {/* First Card: Venue and Booking Items */}
              <div className="first-card rounded-xl card border border-solid border-gravel p-8 w-8/12 lg:w-full"> {/* Original classes */}
                <div className="venue">
                  <p className="label2 uppercase title-gradient"> {/* Original classes */}
                    You will be playing at
                  </p>
                  <h3 className="m-0">{name}</h3> {/* Original structure */}
                </div>

                {bookingData.bookings.map((booking, index) => (
                  <div key={booking.id || index} className="court flex flex-col gap-2 pt-6"> {/* Original classes */}
                    <p className="label2 uppercase title-gradient">Booking {bookingData.bookings.length > 1 ? index + 1 : ''}:</p> {/* Show index only if multiple */}
                    <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                      <div className="flex flex-col gap-2">
                        <p className="body font-medium m-0">{booking.sportTypeName || 'N/A'} - {booking.courtName || 'N/A'}</p> {/* Original structure */}
                        <div className="flex gap-8">
                          {/* Use the original formatDate function */}
                          <p className="body m-0">{formatDate(booking.date)}</p>
                          <p className="body m-0">{booking.earliest} - {booking.latest}</p>
                        </div>
                        {/* Display price per booking item */}
                        <p className="body m-0">{booking.activityPrice?.toLocaleString() ?? 'N/A'} LKR</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Second Card: Cost Summary */}
              <div className="second-card rounded-xl card border border-solid border-gravel p-8 w-4/12 lg:w-full h-fit"> {/* Original classes */}
                <div className="flex flex-col gap-3">
                  <div className="cost flex justify-between items-center">
                    <p className="body m-0">Subtotal</p>
                    <p className="body m-0">{bookingData.totalPrice.toLocaleString()} LKR</p>
                  </div>
                  <div className="cost flex justify-between items-center">
                    <p className="body m-0">Handling Fee</p>
                    {/* Display fee as percentage or amount based on original UI */}
                    <p className="body m-0">5%</p>
                    {/* Or display the calculated amount:
                    <p className="body m-0">{handlingFee.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} LKR</p>
                    */}
                  </div>
                  <hr className="border-gravel my-2" /> {/* Original class */}
                  <div className="cost flex justify-between items-center">
                    <h4 className="m-0">Total Cost</h4>
                    <h4 className="m-0">
                      {(bookingData.totalPrice + handlingFee).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} LKR
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            {/* Edit booking button - only show if not from payment link */}
            {!paymentId && (
              <div className="flex w-fit mb-6"> {/* Original structure */}
                <Button
                  btnText="Edit your booking"
                  click={() => {
                    try {
                      // Store the current state (which is normalized for display)
                      // Ensure the edit page can handle this format or re-normalize if needed
                      localStorage.setItem("editBookingData", JSON.stringify(bookingData));
                      console.log("Storing edit data:", bookingData);
                      navigate(`/select-sport?locationId=${locationId}&edit=true`);
                    } catch (error) {
                      console.error("Error preparing data for editing:", error);
                      toast.error("Failed to prepare booking for editing");
                    }
                  }}
                  classes="!py-2 !px-4 !mt-0 !w-fit" // Original classes
                />
              </div>
            )}

            {/* Payment button */}
            <div className="flex w-[360px] md:w-full"> {/* Original structure */}
              <AuthStatus>
                {(isLoggedIn) => // Removed firstName as it wasn't used in original button text
                  isLoggedIn ? (
                    <Button
                      click={payNow}
                      btnText={isLoading ? "Processing..." : "Pay Now"} // Original text
                      disabled={isLoading || !isPayHereLoaded} // Disable if loading or PayHere not ready
                    // Add original classes if any, or keep default Button styles
                    />
                  ) : (
                    <Button
                      click={completeLogin}
                      btnText="Login to complete your booking" // Original text
                      disabled={isLoading}
                    // Add original classes if any, or keep default Button styles
                    />
                  )
                }
              </AuthStatus>
            </div>

            {/* Cancellation Policy */}
            <p className="body mt-4"> {/* Original class */}
              Once your booking is confirmed, cancellations are not permitted.
              Please confirm your details before proceeding to make the payment.
            </p>
          </div>
        ) : (
          // Render nothing further if loading or no data (handled above)
          null
        )}
      </div>
    </div>
  );
};

export default ConfirmBookingComp;

