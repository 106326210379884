// timeUtils.ts
import dayjs from "dayjs";
import moment from "moment-timezone";

// Timing interface
export interface Timing {
  startTime: string;
  endTime: string;
}

// Time interval interface
export interface TimeInterval {
  startAt: string;
  endAt: string;
  isOccupied: boolean;
  isClosed: boolean;
}

// Updated time slot interface with occupation status
export interface FormattedTimeSlot {
  timeString: string;
  isOccupied: boolean;
  isClosed: boolean;
}

/**
 * Determines if a time string is in 12-hour format (containing AM/PM)
 */
export function is12HourFormat(timeStr: string): boolean {
  return timeStr.includes("AM") || timeStr.includes("PM");
}

/**
 * Converts a time string from 12-hour format to 24-hour format
 * @param time12h Time in 12-hour format (e.g., "9:00 AM")
 * @returns Time in 24-hour format (e.g., "09:00")
 */
export function convertTo24HourFormat(time12h: string): string {
  if (!time12h) return "";

  // Check if the time is already in 24-hour format (no AM/PM)
  if (!is12HourFormat(time12h)) {
    return time12h;
  }

  // Extract the time and period
  const isPM = time12h.includes("PM");
  const timeOnly = time12h.replace(/\s?[AP]M$/i, "").trim();
  const [hourStr, minuteStr] = timeOnly.split(":");

  let hour = parseInt(hourStr, 10);
  const minute = parseInt(minuteStr, 10);

  // Convert the hour to 24-hour format
  if (isPM && hour < 12) {
    hour += 12;
  } else if (!isPM && hour === 12) {
    hour = 0;
  }

  // Format the result
  return `${hour.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")}`;
}

/**
 * Converts a time string from 24-hour format to 12-hour format
 * @param time24h Time in 24-hour format (e.g., "14:30")
 * @returns Time in 12-hour format (e.g., "2:30 PM")
 */
export function convertTo12HourFormat(time24h: string): string {
  if (!time24h) return "";

  // If already in 12-hour format, return as is
  if (is12HourFormat(time24h)) {
    return time24h;
  }

  // Parse hours and minutes
  const [hourStr, minuteStr] = time24h.split(":");
  let hour = parseInt(hourStr, 10);
  const minute = parseInt(minuteStr, 10);

  // Determine AM/PM
  const period = hour >= 12 ? "PM" : "AM";

  // Convert hour to 12-hour format
  if (hour > 12) {
    hour -= 12;
  } else if (hour === 0) {
    hour = 12;
  }

  // Format the result
  return `${hour}:${minute.toString().padStart(2, "0")} ${period}`;
}

/**
 * Ensures time is in 12-hour format
 * Converts if needed or returns original if already in 12-hour format
 */
export function ensureTimeIs12HourFormat(timeStr: string): string {
  return is12HourFormat(timeStr) ? timeStr : convertTo12HourFormat(timeStr);
}

/**
 * Ensures time is in 24-hour format
 * Converts if needed or returns original if already in 24-hour format
 */
export function ensureTimeIs24HourFormat(timeStr: string): string {
  return is12HourFormat(timeStr) ? convertTo24HourFormat(timeStr) : timeStr;
}

/**
 * Formats time intervals from API into readable strings with occupation status
 * Uses 12-hour format with AM/PM
 */
export function formatTimeIntervals(data: TimeInterval[]): FormattedTimeSlot[] {
  return data.map((item) => {
    // Extract and format start time in UTC with 12-hour format
    const startTime = moment.tz(item.startAt, "UTC").format("h:mm A");
    // Extract and format end time in UTC with 12-hour format
    const endTime = moment.tz(item.endAt, "UTC").format("h:mm A");

    // Combine start and end times and include occupation status
    return {
      timeString: `${startTime} - ${endTime}`,
      isOccupied: item.isOccupied,
      isClosed: item.isClosed,
    };
  });
}

/**
 * Normalizes the format of Timing objects to ensure consistent display
 * Can convert to either 12-hour or 24-hour format based on parameter
 */
export function normalizeTimingFormat(
  timing: Timing,
  use24HourFormat: boolean = false
): Timing {
  if (use24HourFormat) {
    return {
      startTime: ensureTimeIs24HourFormat(timing.startTime),
      endTime: ensureTimeIs24HourFormat(timing.endTime),
    };
  } else {
    return {
      startTime: ensureTimeIs12HourFormat(timing.startTime),
      endTime: ensureTimeIs12HourFormat(timing.endTime),
    };
  }
}

/**
 * Converts time string to minutes for comparison
 * Handles both 24-hour format and 12-hour format with AM/PM
 */
export function timeToMinutes(time: string): number {
  // Check if time contains AM/PM
  if (is12HourFormat(time)) {
    const period = time.includes("AM") ? "AM" : "PM";
    // Remove the AM/PM and trim
    const timePart = time.replace(period, "").trim();
    const [hours, minutes] = timePart.split(":").map(Number);

    // Convert to 24-hour format for calculation
    let hours24 = hours;
    if (period === "PM" && hours !== 12) {
      hours24 = hours + 12;
    } else if (period === "AM" && hours === 12) {
      hours24 = 0;
    }

    return hours24 * 60 + minutes;
  } else {
    // Original handling for 24-hour format
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  }
}

/**
 * Checks if selected timings are continuous
 */
export function checkTimingContinuity(timings: Timing[]): boolean {
  if (timings.length <= 1) return true;

  for (let i = 0; i < timings.length - 1; i++) {
    const currentEnd = timeToMinutes(timings[i].endTime);
    const nextStart = timeToMinutes(timings[i + 1].startTime);

    if (nextStart !== currentEnd) {
      return false;
    }
  }

  return true;
}

/**
 * Gets dates for the next three weeks starting from a selected date
 */
export function getNextThreeWeeks(selectedDate: dayjs.Dayjs | null): string[] {
  if (!selectedDate) return [];

  return [
    selectedDate,
    selectedDate.add(1, "week"),
    selectedDate.add(2, "week"),
    selectedDate.add(3, "week"),
  ].map((date) => date.format("DD MMM"));
}

/**
 * Finds earliest start time and latest end time from a list of timings
 * Always returns times in the format specified by the returnIn24HourFormat parameter
 */
export function findEarliestAndLatestTimes(
  selectedTimings: Timing[],
  returnIn24HourFormat: boolean = true
): {
  earliest: string | null;
  latest: string | null;
} {
  if (selectedTimings.length === 0) {
    return { earliest: null, latest: null };
  }

  let earliest = selectedTimings[0].startTime;
  let latest = selectedTimings[0].endTime;

  selectedTimings.forEach((timing) => {
    // Compare using timeToMinutes to handle both formats correctly
    if (timeToMinutes(timing.startTime) < timeToMinutes(earliest)) {
      earliest = timing.startTime;
    }
    if (timeToMinutes(timing.endTime) > timeToMinutes(latest)) {
      latest = timing.endTime;
    }
  });

  // Convert to requested format
  if (returnIn24HourFormat) {
    return {
      earliest: ensureTimeIs24HourFormat(earliest),
      latest: ensureTimeIs24HourFormat(latest),
    };
  } else {
    return {
      earliest: ensureTimeIs12HourFormat(earliest),
      latest: ensureTimeIs12HourFormat(latest),
    };
  }
}
